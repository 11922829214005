import React from 'react';
import i18next from 'i18next';
import './index.css';
import ThemeSetting from '../theme';
import Dropdown from '../dropdown';

const Topnav = ({ onChange, sidebar_items }) => {
    // let names = []
    // sidebar_items.forEach(item => {
    //     names.push({ nonTranslate: item.name, translate: i18next.t(`menu.${item.name}`) })
    //     if (item.childs) {
    //         item.childs.forEach(i => names.push({ nonTranslate: i.name, translate: i18next.t(`menu.${i.name}`) }))
    //     }
    // })

    return (
        <div className='topnav'>
            <div className="topnav__search">
                <input type="text" placeholder='Search menu...' onChange={e => {
                    if (e.target.value) {
                        // let compared = names.map(n => n.translate).filter(n => n.localeCompare(e.target.value, 'en', { ignorePunctuation: true }));
                        // if (compared.length) {
                        //     console.log(compared)
                        // }
                    } else onChange(null)
                }} />
                {/* <i className='bx bx-search'></i> */}
            </div>
            <div div className="topnav__right" >
                <div className="topnav__right-item">
                    {/* <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    /> */}
                </div>
                <div className="topnav__right-item">
                    {/* <Dropdown
                        icon='bx bx-bell'
                        badge='12'
                        contentData={notifications}
                        renderItems={(item, index) => renderNotificationItem(item, index)}
                        renderFooter={() => <Link to='/'>View All</Link>}
                    /> */}
                </div>
                <div className="topnav__right-item">
                    <ThemeSetting />
                </div>
            </div >
        </div >
    )
}

export default Topnav;