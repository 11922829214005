import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';


export const findTransactions = createAsyncThunk(
    'findTransactions',
    async (data) => {
        return await api.findTransactions(data);
    }
)

export const findServiceList = createAsyncThunk(
    'findServiceList',
    async () => {
        return await api.findServiceList();
    }
)

export const findShopList = createAsyncThunk(
    'findShopList',
    async (data) => {
        return api.findShopList(data);
    }
)

export const findSubmerchantList = createAsyncThunk(
    'findSubmerchantList',
    async (data) => {
        return api.findSubmerchantList(data);
    }
)

export const detailTrans = createAsyncThunk(
    'detailTrans',
    async (data) => {
        return api.detailTransaction(data);
    }
)

export const exportTransactions = createAsyncThunk(
    'exportTransactions',
    async (data) => await api.exportTransactions(data)
)

const initialState = {
    data: [],
    count: 0,
    services: [],
    subMerchants: [],
    shops: [],
    detailTrans: {}
}

export const transHistorySlice = createSlice({
    name: 'transHistory',
    initialState,
    reducers: {
        clearTransDetail: (state, action) => {
            state.detailTrans = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findTransactions.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.count = action.payload.total;
            })
            .addCase(findServiceList.fulfilled, (state, action) => {
                state.services = action.payload.data;
            })
            .addCase(findShopList.fulfilled, (state, action) => {
                const ownerShops = [{ "id": "ownerOnly", "name": "Owner Only[-]", "phone": "" }];
                state.shops = [...ownerShops, ...action.payload.data];
            })
            .addCase(findSubmerchantList.fulfilled, (state, action) => {
                state.subMerchants = action.payload.data;
            })
            .addCase(detailTrans.fulfilled, (state, action) => {
                state.detailTrans = action.payload.data;
            })
            .addCase(exportTransactions.fulfilled, (state) => {
                state.exportSuccess = true
            })
    }
})

export const { clearTransDetail } = transHistorySlice.actions;

export default transHistorySlice.reducer;