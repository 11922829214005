import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import CardStatus from '../../components/cardStatus';
import { fetchMerchantBalance, fetchMerchantPoint } from './dashboardSlice';
import storage from '../../services/storage';
import helper from '../../services/helper';

import MerchantSaleReport from './merchantSaleReport';
import ShopSaleReport from './shopSaleReport';
import SellingShops from './sellingShops';
import SellingSubMerchant from './sellingSubMerchant';
import TransByService from './transByService';

import Loading from '../loading';

const Dashboard = () => {
    const dashboardReducer = useSelector(state => state.dashboard);
    const user = JSON.parse(storage.session.getItem('user'));
    const dispatch = useDispatch();

    useEffect(() => {
        let query = { merchantId: user.id };

        // if (user.isSubMerchant) {
        //     query = { merchantId: user.owner.id }
        // }

        dispatch(fetchMerchantBalance(query))
        // dispatch(fetchMerchantPoint(query))
    }, [])


    return (
        <div>
            <h2 className="page-header">Dashboard</h2>
            <Row>
                <Col md={7} sm={24} >
                    {dashboardReducer.loadingMerchantBalance && <Loading showIcon={true} />}
                    <CardStatus icon={"bx bx-dollar"} count={`${helper.toNumberFormat(dashboardReducer.merchantBalance)}`} countInfo={"MMK"} title={"Current Balance"} />
                </Col>
                {/* <Col md={7} sm={24}>
                    {dashboardReducer.loadingMerchantPoint && <Loading showIcon={true} />}
                    <CardStatus icon={"bx bxs-gift"} count={`${helper.toNumberFormat(dashboardReducer.merchantPoint)}`} title={"Current Point"} />
                </Col> */}
            </Row>
            {/* <Row>
                <Col md={12}>
                    <MerchantSaleReport />
                </Col>
                <Col md={12}>
                    <ShopSaleReport />
                </Col>
            </Row> */}
            {/* <Row>
                <Col md={12}>
                    <SellingShops />
                </Col>
                <Col md={12}>
                    <SellingSubMerchant />
                </Col>
            </Row> */}
            <Row>
                <Col md={24}>
                    <TransByService />
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <SellingShops />
                </Col>
            </Row>
        </div>
    )
}

export default Dashboard
