import React, { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Table } from 'antd';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import helper from '../../services/helper';
import storage from '../../services/storage';
import { topSellingSubmerchant } from './dashboardSlice';
const { RangePicker } = DatePicker;

const columns = [
    {
        title: '#',
        key: '#',
        width: 30,
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.name'),
        key: 'name',
        dataIndex: 'submerchant',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.phone'),
        key: 'phone',
        dataIndex: 'submerchant',
        render: (value) => <span>{value.phone}</span>
    },
    {
        title: i18next.t('common.amount'),
        key: 'origAmount',
        dataIndex: 'origAmount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.discount'),
        key: 'discount',
        dataIndex: 'discount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.fee'),
        key: 'fees',
        dataIndex: 'fees',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.net'),
        key: 'net',
        dataIndex: 'origAmount',
        render: (_, record) => <span>{helper.toNumberFormat(record.origAmount + record.discount - record.fees)}</span>
    },
    {
        title: i18next.t('common.txnQty'),
        key: 'txnQty',
        dataIndex: 'txnCount'
    }
]
const SellingSubMerchant = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let search = {
                merchantId: user.id
            }
            if (date.length) {
                search.startDate = moment(date[0]).format('YYYY-MM-DD');
                search.endDate = moment(date[1]).format('YYYY-MM-DD');
            } else search = {}

            let result = await dispatch(topSellingSubmerchant(search)).unwrap();

            setData(helper.assignOrderNumber(result.data, 0));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <div className="row" style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                            <Col md={9} sm={24}>
                                <label className="label">{i18next.t('common.sellingSubMerchant')}</label>
                            </Col>

                            <Col md={{ span: 14, offset: 1 }} sm={24}>
                                <Row>
                                    <Col md={21} >
                                        <RangePicker format={"YYYY-MM-DD"} onChange={e => setDate(e)} allowClear={true} />
                                    </Col>
                                    <Col md={1}>
                                        <SearchOutlined onClick={() => fetchData()} style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col>
                                    {/* <Col md={2}>
                                        <PrinterOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col> */}
                                </Row>
                            </Col>
                        </div>
                        <div className="row">
                            <Table loading={isLoading} style={{ width: "100%", height: '400px' }} columns={columns} dataSource={data} scroll={{ y: 350 }} pagination={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellingSubMerchant;