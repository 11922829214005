import React from 'react';
import { Spin } from 'antd';
import i18next from 'i18next';

import './style.css'

export default function Loading({ children, showIcon = false }) {
    return (<>
        <Spin tip={showIcon ? '' : i18next.t('common.loading')} size="large" >
            {children}
        </Spin>
    </>);
}