import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import storage from '../../services/storage';

export const fetchMerchantBalance = createAsyncThunk(
    'fetchMerchantBalance',
    async (args) => {
        return await api.merchantBalance(args);
    }
)

export const fetchMerchantPoint = createAsyncThunk(
    'fetchMerchantPoint',
    async (args) => {
        return await api.merchantPoint(args);
    }
)

export const shopSaleReport = createAsyncThunk(
    'shopSaleReport',
    async (data) => await api.shopSaleReport(data)
)

export const topSellingSubmerchant = createAsyncThunk(
    'topSellingSubmerchant',
    async (data) => await api.topSellingSubmerchant(data)
)

export const transactionByService = createAsyncThunk(
    'transactionByService',
    async (data) => await api.transactionByService(data)
)

export const merchantSaleReport = createAsyncThunk(
    'merchantSaleReport',
    async (data) => await api.merchantSaleReport(data)
)

const initialState = {
    merchantBalance: 0,
    merchantPoint: 0,
    loadingMerchantBalance: false,
    loadingMerchantPoint: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMerchantBalance.pending, (state, action) => {
                state.loadingMerchantBalance = true
            })
            .addCase(fetchMerchantPoint.pending, (state, action) => {
                state.loadingMerchantPoint = true
            })
            .addCase(fetchMerchantBalance.fulfilled, (state, action) => {
                state.merchantBalance = action.payload.data.balance;
                state.loadingMerchantBalance = false
                state.loadingMerchantPoint = false
            })
            .addCase(fetchMerchantPoint.fulfilled, (state, action) => {
                state.merchantPoint = action.payload.data.point;
                state.loadingMerchantPoint = false
            })
            .addCase(fetchMerchantBalance.rejected, (state, action) => {
                state.loadingMerchantBalance = false
            })
            .addCase(fetchMerchantPoint.rejected, (state, action) => {
                state.loadingMerchantPoint = false
            })
            .addCase(shopSaleReport.fulfilled, (state, action) => {
                state.sellingShop = action.payload
            })
            .addCase(topSellingSubmerchant.fulfilled, (state, action) => {
                state.sellingSubMerchant = action.payload
            })
            .addCase(transactionByService.fulfilled, (state, action) => {
                state.transByService = action.payload
            })
            .addCase(merchantSaleReport.fulfilled, (state, action) => {
                state.mSaleReport = action.payload;
            })
    }
})

export default dashboardSlice.reducer;