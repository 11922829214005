import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const getMenuByRole = createAsyncThunk(
    'getMenuByRole',
    async () => await api.getMenuByRole()
)

const initialState = {
    themeMode: 'theme-mode-dark',
    colorMode: 'theme-color-ocean',
    language: 'en',
    _navs: []
};


export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        themeMode: (state, action) => {
            state.themeMode = action.payload;
        },
        colorMode: (state, action) => {
            state.colorMode = action.payload;
        },
        changeLanguage: (state, action) => {
            state.language = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMenuByRole.fulfilled, (state, action) => {
            state._navs = action.payload.data;
        })
    }
})

export const { themeMode, colorMode, changeLanguage } = layoutSlice.actions;
export const selectValue = (state) => state.layout;

export default layoutSlice.reducer;