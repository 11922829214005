import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const earnPointHistory = createAsyncThunk(
    'earnPointHistory',
    async (data) => await api.earnPointHistory(data)
)

export const exportEarnPointHistory = createAsyncThunk(
    'exportEarnPointHistory',
    async (data) => await api.exportEarnPointHistory(data)
)

export const settlementPointHistory = createAsyncThunk(
    'settlementPointHistory',
    async (data) => await api.settlementPointHistory(data)
)

export const settlementPoint = createAsyncThunk(
    'settlementPoint',
    async (data) => await api.settlementPoint(data)
)

const initialState = {

}

export const pointSlice = createSlice({
    name: 'point',
    initialState,
    reducers: {}
})

export default pointSlice.reducer;