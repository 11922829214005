import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Col, Row, Select, Radio, Input, Table } from 'antd';
import { ReloadOutlined, PlusCircleFilled, DeleteOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';

import helper from '../../services/helper';
import storage from '../../services/storage';
import { createApiKey, updateApiKey, onlineMerchantApis, detailApiKey } from './apiKeySlice';
import Loading from '../loading';

const ApiKeyForm = (props) => {
    const dispatch = useDispatch();
    const apiKeyRedeucer = useSelector(state => state.apiKey);
    const currentLang = storage.local.getItem('lang') || 'en';

    const [apiKey, setApiKey] = useState('')
    const [restrictType, setRestrictType] = useState('none');
    const [apiRestriction, setApiRestriction] = useState(0);
    const [ipList, setIPList] = useState([]);
    const [name, setName] = useState('');
    const [apisSelected, setAPIsSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(onlineMerchantApis())

        if (props.mode === 'update') {
            fetchDetail();
        }
    }, [props])

    const fetchDetail = async () => {
        try {
            setIsLoading(true);

            let result = await dispatch(detailApiKey({ id: props.id })).unwrap();

            let { name, key, restrictApi, restrictType, permissions, ip } = result.data;

            let IPs = []
            if (restrictType === 'ip') {
                for (let i = 0; i < ip.length; i++) {
                    IPs.push({ ip: ip[i], editable: false })
                }
            }

            setIPList(IPs);
            setName(name);
            setApiKey(key);
            setApiRestriction(restrictApi ? 1 : 0);
            setRestrictType(restrictType);
            setAPIsSelected(permissions);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleNewItem = () => {
        let dataCopy = cloneDeep(ipList);
        dataCopy.push({ ip: '', editable: true });
        setIPList(dataCopy);
    }

    const handleEnableEditableIP = (index) => {
        let dataCopy = cloneDeep(ipList);
        dataCopy[index]['editable'] = true;
        setIPList(dataCopy);
    }

    const handleChangeEachIP = (value, index) => {
        let dataCopy = cloneDeep(ipList);
        dataCopy[index]['ip'] = value;
        setIPList(dataCopy);
    }

    const handleDeleteIP = (index) => {
        let dataCopy = cloneDeep(ipList);

        let objData = dataCopy[index];
        if (objData && objData.ip) {
            helper.confirm(i18next.t('question.removeIP')).then(rs => {
                dataCopy = dataCopy.filter((i, idx) => idx !== index);
                setIPList(dataCopy)
            })
        } else {
            dataCopy = dataCopy.filter((i, idx) => idx !== index);
            setIPList(dataCopy)
        }
    }

    const handleUpdateIP = (index) => {
        let dataCopy = cloneDeep(ipList);
        let objIp = dataCopy[index];

        if (!objIp.ip) {
            return helper.toast('error', i18next.t('common.pleaseInput') + ' ' + i18next.t('common.ip'))
        }

        let valid = helper.checkIPAddress(objIp.ip);
        if (!valid) {
            return helper.toast('error', i18next.t('common.ipInvalid'))
        }

        let duplicate = dataCopy.filter(i => i.ip === objIp.ip);
        if (duplicate.length > 1) {
            return helper.toast('error', i18next.t('common.ipExists'))
        }

        dataCopy[index]['editable'] = false;
        setIPList(dataCopy);
    }

    const handleSubmit = async () => {
        try {
            if (!name) {
                return helper.toast('error', i18next.t('common.pleaseInput') + ' ' + i18next.t('common.name'))
            }
            if (!apiKey) {
                return helper.toast('error', i18next.t('common.pleaseInput') + ' ' + i18next.t('common.apiKey'))
            }
            if (restrictType === 'ip') {
                let flag = false;
                for (let i = 0; i < ipList.length; i++) {
                    if (!ipList[i].ip) {
                        flag = true
                        break;
                    }
                }
                if (flag) {
                    return helper.toast('error', i18next.t('required_desc.ipAtKeyRestriction'))
                }
            }
            if (apiRestriction && !apisSelected.length) {
                return helper.toast('error', i18next.t('required_desc.chooseApiRestriction'))
            }
            setIsLoading(true);

            let paramsToSend = {
                name, key: apiKey,
                restrictType,
                ip: ipList.map(i => i.ip),
                permissions: apisSelected,
                restrictApi: apiRestriction === 1 ? true : false
            }

            if (props.mode === 'update') {
                paramsToSend.id = props.id
                await dispatch(updateApiKey(paramsToSend)).unwrap();
            } else {
                await dispatch(createApiKey(paramsToSend)).unwrap();
            }

            helper.toast('success', i18next.t('common.successfully'));
            props.handleCloseForm();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    return (
        <>
            {isLoading && <Loading />}
            <Col md={24}>
                <span>{i18next.t('common.apiKeyNote1')}</span>

                <Form
                    className="mt-5"
                    labelAlign="left"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}>

                    <Form.Item label={i18next.t('common.name')} required={true}>
                        <Input value={name} onChange={e => setName(e.target.value)} style={{ width: '450px' }} placeholder={i18next.t('common.name')} />
                    </Form.Item>

                    <Form.Item label={i18next.t('common.apiKey')} required={true}>
                        <Input style={{ width: '450px' }} value={apiKey} onChange={e => setApiKey(e.target.value)} placeholder={i18next.t('common.apiKey')} />
                        <br />
                        <Button className="mt-2" icon={<ReloadOutlined />}
                            onClick={() => setApiKey(helper.generateString(40))}>{i18next.t('common.generateKey')}</Button>
                    </Form.Item>

                    <Form.Item label={i18next.t('common.keyRestriction')}>
                        <Radio.Group value={restrictType} onChange={e => setRestrictType(e.target.value)}>
                            {
                                [{ id: 'none', name: i18next.t('common.none') }, { id: 'ip', name: i18next.t('common.ipAddress') }]
                                    .map((item, index) => <Row key={`key_restriction_${index}`}><Radio value={item.id}>{item.name}</Radio></Row>)
                            }
                        </Radio.Group>

                        {restrictType === 'ip' && <>
                            <p className="label">{i18next.t('common.acceptRequest')}</p>
                            <p>{i18next.t('common.apiKeyNote2')}</p>

                            <Button className="mt-2" icon={<PlusCircleFilled />} onClick={() => handleNewItem()}>{i18next.t('common.newItem')}</Button>

                            {ipList.length ? <Table columns={[
                                {
                                    title: i18next.t('common.ip'),
                                    key: 'ip',
                                    dataIndex: 'ip',
                                    fixed: 'left',
                                    width: '80%',
                                    render: (val, record, index) => <Input value={val} disabled={!record.editable} onChange={e => handleChangeEachIP(e.target.value, index)} />
                                },
                                {
                                    title: i18next.t('common.action'),
                                    key: 'action',
                                    render: (_, record, index) => {
                                        if (!record.editable)
                                            return (<>
                                                <EditOutlined onClick={() => handleEnableEditableIP(index)} className="mr-5" style={{ fontSize: '25px', cursor: 'pointer' }} />
                                                <DeleteOutlined onClick={() => handleDeleteIP(index)} style={{ fontSize: '25px', cursor: 'pointer' }} />
                                            </>)
                                        else
                                            return (<>
                                                <CheckOutlined className="mr-5" onClick={() => handleUpdateIP(index)} style={{ fontSize: '25px', cursor: 'pointer' }} />
                                                <DeleteOutlined onClick={() => handleDeleteIP(index)} style={{ fontSize: '25px', cursor: 'pointer' }} />
                                            </>)
                                    }
                                }
                            ]} dataSource={ipList} pagination={false} scroll={{ y: 240 }} /> : null}
                        </>}

                    </Form.Item>

                    <Form.Item label={i18next.t('common.apiRestriction')}>
                        <Radio.Group value={apiRestriction} onChange={e => setApiRestriction(e.target.value)}>
                            {
                                [{ id: 0, name: i18next.t('common.dontRestrict') }, { id: 1, name: i18next.t('common.restrict') }]
                                    .map((item, index) => <Row key={`apiRestriction_${index}`}><Radio value={item.id}>{item.name}</Radio></Row>)
                            }
                        </Radio.Group>
                        <br />
                        {apiRestriction === 1 && <>
                            <Select value={apisSelected} onChange={e => { setAPIsSelected(e) }} style={{ width: '450px' }} mode="multiple" className="mt-2" placeholder={i18next.t('common.sltApis')}>
                                {apiKeyRedeucer.oMerchantAPIs.map((item, index) => <Select.Option key={`oMerchantAPIs_${index}`} value={item.id}>{item.name[currentLang]}</Select.Option>)}
                            </Select>
                        </>}
                    </Form.Item>

                    <hr />
                    <Form.Item wrapperCol={{ span: 2, offset: 10 }}>
                        <Button onClick={() => handleSubmit()} htmlType="button" type="primary" style={{ position: 'absolute' }}>
                            {i18next.t('common.submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </Col >
        </>
    )
}

export default ApiKeyForm;