import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Tag, Form, Input, Row, Col, Select, DatePicker, Button, Modal, Dropdown, Menu } from 'antd';
import { SearchOutlined, DownOutlined, EditOutlined, LockOutlined, CheckCircleOutlined, PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import moment from 'moment';

import storage from '../../services/storage';
import helper from '../../services/helper';
import { findShops, activeShop, lockShop, exportShops } from './shopSlice';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.code'),
        dataIndex: 'code',
        key: 'code'
    },
    {
        title: i18next.t('common.name'),
        key: 'name',
        dataIndex: 'name'
    },
    {
        title: i18next.t('common.shortName'),
        key: 'shortName',
        dataIndex: 'shortName'
    },
    {
        title: i18next.t('common.phone'),
        key: 'phone',
        dataIndex: 'phone'
    },
    {
        title: i18next.t('common.address'),
        key: 'address',
        dataIndex: 'address'
    },
    {
        title: i18next.t('common.status'),
        key: 'status',
        dataIndex: 'status',
        render: (value) => <Tag color={value === 'active' ? '#87d068' : '#b80606'}>{value}</Tag>
    },
    {
        title: i18next.t('common.default'),
        key: 'isDefault',
        dataIndex: 'isDefault',
        render: (value) => <Tag color={value ? '#87d068' : '#b80606'}>{value ? 'True' : 'False'}</Tag>
    },
    {
        title: i18next.t('common.createdAt'),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    }
]
const statusList = [{ name: "active" }, { name: "locked" }, { name: "pending" }, { name: "init" }, { name: "inactive" }, { name: "closed" }];

const Shop = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const shopRedeucer = useSelector(state => state.shop);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args, isExport = false) => {
        setLoading(true);
        if (args) {
            skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
            limit = parseInt(args.pageSize)
        }

        let objSearch = {}
        if (search.length) {
            search.forEach(item => {
                objSearch[item.name[0]] = item.value
            })

            if (objSearch.createdAt) {
                objSearch.startDate = moment(objSearch.createdAt[0]).format("YYYY-MM-DD")
                objSearch.endDate = moment(objSearch.createdAt[1]).format("YYYY-MM-DD")
                delete objSearch.createdAt
            }
        }

        if (isExport) {
            let exported = await dispatch(exportShops({ ...objSearch, merchantId: user.id, skip: 0, limit: -1 })).unwrap();
            setLoading(false);
            window.open(exported.link, '_blank')
        }

        const params = {
            ...objSearch,
            merchantId: user.id,
            skip: skip,
            limit: limit
        }

        const result = await dispatch(findShops(params)).unwrap();

        setData(helper.assignOrderNumber(result.data, skip));
        setPagination({ ...pagination, total: result.total || 0 });
        setLoading(false);
    }

    let colsKey = columns.map(c => c.key);
    if (!colsKey.includes('action')) {
        columns.push({
            title: i18next.t('common.action'),
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text, record) => {
                const menu = (<Menu >
                    <Menu.Item key="1" onClick={() => history.push(`/shop/update/${record.id}`)} icon={<EditOutlined />}>
                        {i18next.t('common.update')}
                    </Menu.Item>
                    {record.status === 'active' && <Menu.Item onClick={() => {
                        setLoading(true);
                        dispatch(lockShop({ id: record.id }));
                        helper.toast('success', i18next.t('common.lockedSuccessfully'));
                        setTimeout(() => fetchData(), 1000)
                    }} key="2" icon={<LockOutlined />}>
                        {i18next.t('common.lock')}
                    </Menu.Item>}
                    {record.status === 'locked' && <Menu.Item onClick={() => {
                        setLoading(true);
                        dispatch(activeShop({ id: record.id }));
                        helper.toast('success', i18next.t('common.activeSuccessfully'));
                        setTimeout(() => fetchData(), 1000)
                    }} key="3" icon={<CheckCircleOutlined />}>
                        {i18next.t('common.active')}
                    </Menu.Item>}
                </Menu>);
                return <Dropdown overlay={menu}>
                    <Button>
                        {i18next.t('common.action')} <DownOutlined />
                    </Button>
                </Dropdown>
            }
        })
    }

    return (
        <div>
            <div className="row">
                <h2 className="page-header">{i18next.t('menu.shopManagement')}</h2>
                <Button className="btn-create" onClick={() => history.push('/shop/create')} icon={<PlusCircleOutlined />}>{i18next.t('common.create')}</Button>
                <Button className="btn-export" onClick={() => fetchData({}, true)} icon={<DownloadOutlined />}>{i18next.t('common.export')}</Button>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form form={form}
                                layout="vertical"
                                onFinish={value => fetchData()}
                                onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item name="name" label={i18next.t('common.name')} >
                                            <Input placeholder={i18next.t('common.name')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="senderPhone" label={i18next.t('common.phone')} >
                                            <Input placeholder={i18next.t('common.phone')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="status" label={i18next.t('common.status')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.status')}>
                                                {
                                                    statusList.map((item, index) => <Select.Option key={`${index}_status`} value={item.name}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="createdAt" label={i18next.t('common.createdAt')}>
                                            <RangePicker format={dateFormat} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={22}></Col>
                                    <Col span={2}>
                                        <Form.Item>
                                            <Button icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                            <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                                pagination={pagination} onChange={changed => fetchData(changed)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shop;