import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';


export const findClientRoles = createAsyncThunk(
    'findClientRoles',
    async () => {
        return await api.findClientRoles({ skip: 0, limit: -1 });
    }
)

export const getPermissionForWeb = createAsyncThunk(
    'getPermissionForWeb',
    async () => await api.findPermissions({ skip: 0, limit: -1, channel: 'web' })
)

export const getPermissionForMobile = createAsyncThunk(
    'getPermissionForMobile',
    async () => await api.findPermissions({ skip: 0, limit: -1, channel: 'mobile' })
)

export const createUserRole = createAsyncThunk(
    'createUserRole',
    async (data) => await api.createUserRole(data)
)

export const updateClientRolePerms = createAsyncThunk(
    'updateClientRolePerms',
    async (data) => await api.updateClientRolePerms(data)
)

export const getClientMenu = createAsyncThunk(
    'getClientMenu',
    async () => await api.getClientMenu()
)

export const merchantMenu = createAsyncThunk(
    'merchantMenu',
    async () => await api.merchantMenu()
)

const initialState = {
    roles: [],
    isLoading: false,
    webPermissions: [],
    mobilePermission: [],
    clientMenus: [],
    allMerchantMenu: []
}

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findClientRoles.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(findClientRoles.fulfilled, (state, action) => {
                state.roles = action.payload.data;
                state.isLoading = false;
            })
            .addCase(getPermissionForWeb.fulfilled, (state, action) => {
                state.webPermissions = action.payload.data;
            })
            .addCase(getPermissionForMobile.fulfilled, (state, action) => {
                state.mobilePermission = action.payload.data;
            })
            .addCase(createUserRole.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createUserRole.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(createUserRole.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateClientRolePerms.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateClientRolePerms.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(updateClientRolePerms.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getClientMenu.fulfilled, (state, action) => {
                state.clientMenus = action.payload.data;
            })
            .addCase(merchantMenu.fulfilled, (state, action) => {
                state.allMerchantMenu = action.payload.data;
            })
    }
})


export default roleSlice.reducer;