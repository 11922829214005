import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row, Input, Select, Table, Tag, Dropdown, Menu, Modal } from 'antd';
import { PlusCircleOutlined, SearchOutlined, EditOutlined, LockOutlined, CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';

import helper from '../../services/helper';
import { findApiKeys, enableApiKey, revokeApiKey } from './apiKeySlice';
import ApiKeyForm from './form';

const columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.name'),
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: i18next.t('common.key'),
        key: 'key',
        dataIndex: 'key'
    },
    {
        title: i18next.t('common.status'),
        key: 'status',
        dataIndex: 'status',
        render: (value) => <Tag color={value === 1 ? '#87d068' : '#b80606'}>{value === 1 ? i18next.t('common.enable') : i18next.t('common.revoked')}</Tag>
    },
    {
        title: i18next.t('common.createdBy'),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.updatedBy'),
        key: 'updatedBy',
        dataIndex: 'updatedBy',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.createdAt'),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    },
    {
        title: i18next.t('common.updatedAt'),
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    }
]
const statusList = [{ id: 1, name: i18next.t('common.enable') }, { id: 0, name: i18next.t('common.revoked') }]
const ApiKey = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [mode, setMode] = useState('create');
    const [apiKeyId, setApiKeyId] = useState('');

    useEffect(() => {
        fetchData()
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args) => {
        try {
            setLoading(true);
            if (args) {
                skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
                limit = parseInt(args.pageSize)
            }

            let objSearch = {}
            if (search.length) {
                search.forEach(item => {
                    objSearch[item.name[0]] = item.value
                })
            }

            const params = {
                ...objSearch,
                skip: skip,
                limit: limit
            }

            const result = await dispatch(findApiKeys(params)).unwrap();

            setData(helper.assignOrderNumber(result.data, skip));
            setPagination({ ...pagination, total: result.total || 0 });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    let colsKey = columns.map(c => c.key);
    if (!colsKey.includes('action')) {
        columns.push({
            title: i18next.t('common.action'),
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text, record) => {
                const menu = (<Menu >
                    <Menu.Item key="1" onClick={() => {
                        setMode('update');
                        setApiKeyId(record.id);
                        setShowForm(true);
                    }} icon={<EditOutlined />}>
                        {i18next.t('common.update')}
                    </Menu.Item>
                    {record.status === 1 && <Menu.Item onClick={() => {
                        setLoading(true);
                        dispatch(revokeApiKey({ id: record.id }));
                        helper.toast('success', i18next.t('common.successfully'));
                        setTimeout(() => fetchData(), 50)
                    }} key="2" icon={<LockOutlined />}>
                        {i18next.t('common.revoked')}
                    </Menu.Item>}
                    {record.status === 0 && <Menu.Item onClick={() => {
                        setLoading(true);
                        dispatch(enableApiKey({ id: record.id }));
                        helper.toast('success', i18next.t('common.successfully'));
                        setTimeout(() => fetchData(), 50)
                    }} key="3" icon={<CheckCircleOutlined />}>
                        {i18next.t('common.enable')}
                    </Menu.Item>}
                </Menu>);
                return <Dropdown overlay={menu}>
                    <Button>
                        {i18next.t('common.action')} <DownOutlined />
                    </Button>
                </Dropdown>
            }
        })
    }

    const handleCloseForm = () => {
        setShowForm(false);
        setMode('create');
        setApiKeyId('');
    }

    return (<div>
        <div className="row">
            <h2 className="page-header">{i18next.t('menu.apiKeys')}</h2>
            <Button className="btn-create" onClick={() => setShowForm(true)} icon={<PlusCircleOutlined />}>{i18next.t('common.create')}</Button>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <Form
                            layout="vertical"
                            onFinish={value => fetchData()}
                            onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                            <Row>
                                <Col span={6}>
                                    <Form.Item name="name" label={i18next.t('common.name')} >
                                        <Input placeholder={i18next.t('common.name')} />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item name="key" label={i18next.t('common.key')} >
                                        <Input placeholder={i18next.t('common.key')} />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item name="status" label={i18next.t('common.status')} >
                                        <Select allowClear={true}
                                            placeholder={i18next.t('common.status')}>
                                            {
                                                statusList.map((item, index) => <Select.Option key={`${index}_status`} value={item.id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={22}></Col>
                                <Col span={2}>
                                    <Form.Item>
                                        <Button icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                        <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                            pagination={pagination} onChange={changed => fetchData(changed)} />

                        <Modal title={i18next.t('common.apiKey')}
                            className="text-center modal-detail-transaction"
                            visible={showForm} footer={null}
                            onCancel={() => handleCloseForm()}>
                            <ApiKeyForm handleCloseForm={handleCloseForm} mode={mode} id={apiKeyId} />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ApiKey;