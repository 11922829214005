import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { themeMode, colorMode, getMenuByRole } from './layoutSlice';
import './index.css';
import Sidebar from './sidebar';
import TopNav from './topnav';
import Routes from '../Routes';
import Loading from '../loading';

import storage from '../../services/storage';

import sidebar_items from '../../assets/JsonData/sidebar_routes.json';

const LayoutView = () => {
    const layoutReducer = useSelector(state => state.layout);
    const dispatch = useDispatch();
    const history = useHistory();
    const [nav, setNav] = useState();
    const [isLoading, setLoading] = useState(false);
    const isLogIn = Boolean(storage.session.getItem('session'));

    useEffect(() => {
        if (isLogIn) fetchMenu();
        const themeClass = localStorage.getItem('themeMode', 'theme-mode-light');
        const colorClass = localStorage.getItem('colorMode', 'color-mode-light');

        dispatch(themeMode(themeClass));
        dispatch(colorMode(colorClass));
    }, []);


    if (!isLogIn) {
        history.push("/login");
        return (<></>)
    }

    const fetchMenu = async () => {
        try {
            setLoading(true);
            let result = await dispatch(getMenuByRole()).unwrap();
            setLoading(false);
            setNav(result.data)
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        // ${layoutReducer.themeMode}
        <div className={`layout theme-mode-light ${layoutReducer.colorMode}`}>
            <Sidebar sidebar_items={nav} />
            <div className="layout__content">
                <TopNav sidebar_items={sidebar_items} />
                <div className="layout__content-main">
                    {isLoading ? <Loading /> : <Routes />}
                </div>
            </div>
        </div>
    );
}

export default LayoutView;