import React, { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Table, Select } from 'antd';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import helper from '../../services/helper';
import storage from '../../services/storage';
import { shopSaleReport } from './dashboardSlice';
import { findShopList } from '../transHistory/transHistorySlice';

const { RangePicker } = DatePicker;

const columns = [
    {
        title: '#',
        key: '#',
        width: 30,
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.name'),
        key: 'name',
        dataIndex: 'shop',
        render: (value) => <span>{value.name}</span>
    },
    // {
    //     title: i18next.t('common.phone'),
    //     key: 'phone',
    //     dataIndex: 'shop',
    //     render: (value) => <span>{value.phone}</span>
    // },
    {
        title: i18next.t('common.amount'),
        key: 'origAmount',
        dataIndex: 'origAmount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.discount'),
        key: 'discount',
        dataIndex: 'discount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.fee'),
        key: 'fees',
        dataIndex: 'fees',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.net'),
        key: 'net',
        dataIndex: 'origAmount',
        render: (_, record) => <span>{helper.toNumberFormat(record.origAmount - record.discount - record.fees)}</span>
    },
    {
        title: i18next.t('common.txnQty'),
        key: 'txnQty',
        dataIndex: 'txnCount'
    }
]

const SellingShops = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(storage.session.getItem('user'));
    const transHistoryRedeucer = useSelector(state => state.transHistory);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);
    const [shopId, setShopId] = useState([]);

    useEffect(() => {
        fetchData();
        dispatch(findShopList({ merchantId: user.id }));
    }, [])

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let search = { shopId }

            if (date.length) {
                search.startDate = moment(date[0]).format('YYYY-MM-DD');
                search.endDate = moment(date[1]).format('YYYY-MM-DD');
            }

            let result = await dispatch(shopSaleReport(search)).unwrap();

            setData(helper.assignOrderNumber(result.data, 0));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <div className="row" style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                            <Col md={7} sm={24}>
                                <label className="label">{i18next.t('common.sellingShops')}</label>
                            </Col>

                            <Col md={{ span: 15, offset: 2 }} sm={24}>
                                <Row>
                                    <Col md={10} >
                                        <RangePicker format={"YYYY-MM-DD"} onChange={e => setDate(e)} allowClear={true} />
                                    </Col>
                                    <Col md={12} >
                                        <Select style={{ width: '100%' }} allowClear={true} mode="multiple" onChange={e => setShopId(e)}
                                            placeholder={i18next.t('common.shop')}>
                                            {
                                                transHistoryRedeucer.shops.filter(f => f.id !== 'ownerOnly').map((item, index) => <Select.Option key={`${index}_shop`} value={item.id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Col>
                                    <Col md={1}>
                                        <SearchOutlined onClick={() => fetchData()} style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col>
                                    {/* <Col md={2}>
                                        <PrinterOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col> */}
                                </Row>
                            </Col>
                        </div>
                        <div className="row">
                            <Table loading={isLoading} style={{ width: "100%", height: '400px' }} columns={columns} dataSource={data} scroll={{ y: 350 }} pagination={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellingShops;