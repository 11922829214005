import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row, Form, Button, Input, Switch } from 'antd';
import i18next from 'i18next';

import helper from '../../services/helper';
import storage from '../../services/storage';
import { changePassword } from './profileSlice';

import { logout } from '../../app/commonSlice';
import { userLogout } from '../login/loginSlice';

const Security = () => {
    const history = useHistory();
    const [form] = Form.useForm();

    const [isDisabled, setDisabled] = useState(true);
    const dispatch = useDispatch();


    return (
        <>
            <Col span={24} offset={6}>
                <Row style={{ marginBottom: '20px' }}>
                    <Switch defaultChecked={false} onChange={e => setDisabled(!e)} />
                    <span style={{ fontWeight: 'bold', paddingLeft: 5 }}>{i18next.t('common.modify')}</span>
                </Row>
                <Row >
                    <Form form={form}
                        layout="vertical"
                        labelAlign="left"
                        style={{ width: '50%' }}
                        onFinish={async (values) => {
                            try {
                                let { password, newPassword, confirmPassword } = values;
                                if (newPassword !== confirmPassword) return helper.toast('error', i18next.t('required_desc.comparePasword'));
                                await dispatch(changePassword({ oldPassword: password, newPassword })).unwrap();
                                helper.toast('success', i18next.t('common.changePassSuccess'));
                                dispatch(userLogout());
                                history.push('/login');
                                storage.session.clear();
                            } catch (error) {

                            }
                        }}>
                        <Form.Item
                            name="password"
                            label={i18next.t('common.currentPassowrd')}
                            rules={[{ required: true, message: i18next.t('required_desc.currentPassowrd') }]}>
                            <Input.Password onChange={e => helper.numberOnly(e)} disabled={isDisabled} placeholder={i18next.t('common.currentPassowrd') + " *"} size="large" />
                        </Form.Item>

                        <Form.Item
                            name="newPassword"
                            label={i18next.t('common.newPassword')}
                            rules={[{ required: true, message: i18next.t('required_desc.newPassword') }]}>
                            <Input.Password onChange={e => helper.numberOnly(e)} disabled={isDisabled} placeholder={i18next.t('common.newPassword') + " *"} size="large" />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            label={i18next.t('common.confirmPassword')}
                            rules={[{ required: true, message: i18next.t('required_desc.confirmPassword') }]}>
                            <Input.Password onChange={e => helper.numberOnly(e)} disabled={isDisabled} type="password" placeholder={i18next.t('common.confirmPassword') + " *"} size="large" />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 9, span: 24, }}>
                            <Button disabled={isDisabled} type="primary" htmlType="submit">
                                {i18next.t('common.submit')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Row>
            </Col>
        </>
    )
}

export default Security;