import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const findApiKeys = createAsyncThunk(
    'findApiKeys',
    async (data) => await api.findApiKeys(data)
)

export const revokeApiKey = createAsyncThunk(
    'revokeApiKey',
    async (data) => await api.revokeApiKey(data)
)

export const enableApiKey = createAsyncThunk(
    'enableApiKey',
    async (data) => await api.enableApiKey(data)
)

export const onlineMerchantApis = createAsyncThunk(
    'onlineMerchantApis',
    async () => await api.onlineMerchantApis()
)

export const createApiKey = createAsyncThunk(
    'createApiKey',
    async (data) => await api.createApiKey(data)
)

export const detailApiKey = createAsyncThunk(
    'detailApiKey',
    async (data) => await api.detailApiKey(data)
)

export const updateApiKey = createAsyncThunk(
    'updateApiKey',
    async (data) => await api.updateApiKey(data)
)

const initialState = {
    oMerchantAPIs: []
}

export const apiKeySlice = createSlice({
    name: 'apiKey',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(onlineMerchantApis.fulfilled, (state, action) => {
                state.oMerchantAPIs = action.payload.data;
            })
    }
})

export default apiKeySlice.reducer;