import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const changePassword = createAsyncThunk(
    'changePassword',
    async (data) => await api.changePassword(data)
)

export const fetchDeviceList = createAsyncThunk(
    'fetchDeviceList',
    async (data) => await api.deviceList(data)
)

export const removeDevice = createAsyncThunk(
    'removeDevice',
    async (data) => await api.removeDevice(data)
)



const initialState = {
    objDevice: {},
    isLoading: true
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(changePassword.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(fetchDeviceList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDeviceList.fulfilled, (state, action) => {
                state.objDevice = action.payload;
                state.isLoading = false
            })
            .addCase(fetchDeviceList.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(removeDevice.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(removeDevice.fulfilled, (state, action) => {
                state.isLoading = false
            })
    }
})

export default profileSlice.reducer;