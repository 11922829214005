import React, { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Table } from 'antd';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// import { Bar } from 'react-chartjs-2';
import { cloneDeep } from 'lodash';

// import helper from '../../services/helper';
import { merchantSaleReport } from './dashboardSlice';


const { RangePicker } = DatePicker;

const options = {
    tooltips: {
        enabled: false
    },
    legend: {
        display: true
    },
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            gridLines: {
                drawOnChartArea: true,
            },
            ticks: {
                maxTicksLimit: 8
            }

        }, {
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y-axis-2',
            gridLines: {
                drawOnChartArea: false,
            },
            ticks: {
                maxTicksLimit: 8
            }
        }],
    }
}

const msrBarInit = {
    labels: [],
    datasets: [
        {
            label: "Amount",
            backgroundColor: '#42a6d7',
            borderColor: '#00f',
            borderWidth: 1,
            hoverBackgroundColor: '#00a',
            hoverBorderColor: '#00f',
            yAxisID: 'y-axis-1',
            order: 2,
            data: []
        }, {
            label: "TotalTxn",
            backgroundColor: "#f00",
            borderColor: '#2f962d',
            type: "line",
            yAxisID: 'y-axis-2',
            order: 1,
            fill: false,
            data: []
        }
    ],
};

const MerchantSaleReport = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState([]);
    const [merchantSaleRp, setMerchantSaleRp] = useState({ "totalAmount": 0, "totalFees": 0, "totalTxnQty": 0 });
    const [msrBar, setMsrBar] = useState(msrBarInit);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let search = {}

            if (date.length) {
                search.startDate = moment(date[0]).format('YYYY-MM-DD');
                search.endDate = moment(date[1]).format('YYYY-MM-DD');
            } else search = {}

            let result = await dispatch(merchantSaleReport(search)).unwrap();

            let mSaleReport = [], msrBarLabels = [];
            result.data.map(obj => {
                mSaleReport.push({ amount: obj.origAmount, txnQty: obj.txnCount });
                msrBarLabels.push(obj._id);
            })

            const msrBarCopy = cloneDeep(msrBar);
            msrBarCopy.labels = msrBarLabels;
            let barAmtData = mSaleReport.map(obj => obj.amount);
            let barTxnData = mSaleReport.map(obj => obj.txnQty);
            msrBarCopy.datasets[0].data = barAmtData;
            msrBarCopy.datasets[1].data = barTxnData;

            setMerchantSaleRp(mSaleReport);
            setMsrBar(msrBarCopy);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <div className="row" style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                            <Col md={8} sm={24}>
                                <label className="label">{i18next.t('common.merchantSaleReport')}</label>
                            </Col>

                            <Col md={{ span: 14, offset: 2 }} sm={24}>
                                <Row>
                                    <Col md={21} >
                                        <RangePicker format={"YYYY-MM-DD"} onChange={e => setDate(e)} allowClear={true} />
                                    </Col>
                                    <Col md={1}>
                                        <SearchOutlined onClick={() => fetchData()} style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col>
                                    {/* <Col md={2}>
                                        <PrinterOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col> */}
                                </Row>
                            </Col>
                        </div>
                        <div className="row">
                            {/* <Bar data={msrBar} options={options} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MerchantSaleReport;