import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Tag, Form, Row, Col, DatePicker, Button, Modal, Input } from 'antd';
import { SearchOutlined, PlusCircleFilled } from '@ant-design/icons'
import i18next from 'i18next';
import moment from 'moment';

import { settlementPointHistory, settlementPoint } from './pointSlice';
import helper from '../../services/helper';
import Loading from '../loading';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
var columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.transRefId'),
        dataIndex: 'id',
        key: 'transRefId'
    },
    {
        title: i18next.t('common.phone'),
        key: 'phone',
        dataIndex: 'sender',
        render: (value) => <span>{value.phone}</span>
    },
    {
        title: i18next.t('common.point'),
        key: 'point',
        dataIndex: 'point'
    },
    {
        title: i18next.t('common.transDate'),
        key: 'transDate',
        dataIndex: 'transDate',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    }
]

const SettlePointHistory = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [point, setPoint] = useState('');
    const [pin, setPin] = useState('');
    const [loadingSettle, setLoadingSettle] = useState(false);

    useEffect(() => {
        fetchData();
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args) => {
        setLoading(true);
        if (args) {
            skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
            limit = parseInt(args.pageSize)
        }

        let objSearch = {}
        if (search.length) {
            search.forEach(item => {
                objSearch[item.name[0]] = item.value
            })

            if (objSearch.createdAt) {
                objSearch.startDate = moment(objSearch.createdAt[0]).format("YYYY-MM-DD")
                objSearch.endDate = moment(objSearch.createdAt[1]).format("YYYY-MM-DD")
                delete objSearch.createdAt
            }
        }

        const params = {
            ...objSearch,
            skip: skip,
            limit: limit
        }

        const result = await dispatch(settlementPointHistory(params)).unwrap();

        setData(helper.assignOrderNumber(result.data, skip));
        setPagination({ ...pagination, total: result.total || 0 });
        setLoading(false);
    }

    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.pointSettlement')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form form={form}
                                layout="vertical"
                                onFinish={value => fetchData()}
                                onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                                <Row>
                                    <Col md={12}></Col>
                                    <Col md={6}>
                                        <Form.Item name="createdAt" label={i18next.t('common.transDate')}>
                                            <RangePicker format={dateFormat} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={5} style={{ marginTop: '30px' }}>
                                        <Button className="mr-3" icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                        <Button icon={<PlusCircleFilled />} onClick={() => setShowModal(true)} type="default" htmlType="button">{i18next.t('common.create')}</Button>
                                    </Col>
                                </Row>
                            </Form>

                            <Modal title={i18next.t('common.settlementPoint')} className="text-center"
                                visible={showModal}
                                onOk={async () => {
                                    if (!point) return helper.toast('error', i18next.t('common.pleaseInput') + ' ' + i18next.t('common.point'));
                                    if (!pin) return helper.toast('error', i18next.t('common.pleaseInput') + ' ' + i18next.t('common.password'));

                                    setLoadingSettle(true);
                                    try {
                                        await dispatch(settlementPoint({ pin, point })).unwrap();
                                        helper.toast('success', i18next.t('common.successfully'))
                                        setLoadingSettle(false);
                                        setShowModal(false);
                                        setPoint('');
                                        setPin('');
                                        fetchData();
                                    } catch (error) {
                                        setLoadingSettle(false);
                                    }

                                }}
                                onCancel={() => {
                                    setPoint('');
                                    setPin('');
                                    setShowModal(false);
                                }}
                                okText={i18next.t('common.settle')} >
                                {loadingSettle && <Loading />}
                                <Form layout="vertical" name="otp-verification-form">
                                    <Form.Item
                                        label={i18next.t('common.point')}
                                        required={true}>
                                        <Input type="number" placeholder={i18next.t('common.point')} value={point} onChange={e => setPoint(e.target.value)} />
                                    </Form.Item>

                                    <Form.Item
                                        label={i18next.t('common.password')}
                                        required={true}>
                                        <Input.Password placeholder={i18next.t('common.password')} value={pin} onChange={e => setPin(e.target.value)} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                            <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                                pagination={pagination} onChange={changed => fetchData(changed)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlePointHistory;