import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { Layout, Menu } from 'antd';

import './index.css';
import logo from '../../../assets/images/favicon.png';
// import sidebar_items from '../../../assets/JsonData/sidebar_routes.json';
import storage from '../../../services/storage';

const { Sider } = Layout;
const { SubMenu } = Menu;

// var allMenus = []
// sidebar_items.map(item => {
//     allMenus.push(item);
//     if (item.subMenu) {
//         item.subMenu.map(i => allMenus.push(i))
//     }
// })

// const SidebarItem = props => {
//     const active = props.active ? 'active' : ''

//     return (
//         <div className="sidebar__item">
//             <div className={`sidebar__item-inner ${active}`}>
//                 <i className={props.icon}></i>
//                 <span>
//                     {i18next.t(`menu.${props.title}`)}
//                 </span>
//             </div>
//         </div>
//     )
// }

const Sidebar = ({ sidebar_items }) => {
    const userInfo = JSON.parse(storage.session.getItem('user'));
    const history = useHistory();
    const currentLang = storage.local.getItem('lang') || 'en';

    const [collapsed, setCollapsed] = useState(false);

    var _nav = sidebar_items || []
    if (userInfo.type !== 'thirdparty') {
        _nav = _nav.filter(mn => mn.route !== 'integration')
    }

    return (
        <Sider className="sidebar" collapsible collapsed={collapsed} onCollapse={(e) => setCollapsed(e)}>
            <div className="sidebar__logo">
                <img src={logo} alt="company logo" />
                <div>{i18next.t('common.merchantPortal')}</div>
            </div>
            <Menu mode="inline" >
                {
                    _nav.map((prMenu, index) => {
                        if (!prMenu.subMenu) {
                            return (
                                <Menu.Item className="sidebar__item-inner" icon={<i className={prMenu.icon}></i>}
                                    key={prMenu.name[currentLang]}
                                    onClick={() => history.push(prMenu.route)}>
                                    <span className="label">{prMenu.name[currentLang]}</span>
                                </Menu.Item>
                            )
                        } else {
                            return (
                                <SubMenu key={prMenu.name[currentLang]}
                                    icon={<i className={prMenu.icon}></i>}
                                    title={<span className="label">{prMenu.name[currentLang]}</span>}>
                                    {prMenu.subMenu.map((child, idx) => (
                                        <Menu.Item onClick={() => history.push(child.route)}
                                            key={child.name[currentLang]}>
                                            <span className="label">{child.name[currentLang]}</span>
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            )
                        }
                    })
                }
            </Menu>
        </Sider>
    )
}

export default Sidebar