import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Form, Input, Row, Col, Select, DatePicker, Button, Modal } from 'antd';
import { SearchOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import moment from 'moment';

import storage from '../../services/storage';
import helper from '../../services/helper';
import { findTransactions, findServiceList, findShopList, findSubmerchantList, detailTrans, clearTransDetail, exportTransactions } from './transHistorySlice';
import DetailTrans from './detailTrans';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.transCode'),
        dataIndex: 'code',
        key: 'code'
    },
    {
        title: i18next.t('common.sender'),
        key: 'sender',
        dataIndex: 'sender',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.senderPhone'),
        key: 'senderPhone',
        dataIndex: 'sender',
        render: (value) => <span>{value.phone}</span>
    },
    {
        title: i18next.t('common.transName'),
        key: 'name',
        dataIndex: 'name'
    },
    {
        title: i18next.t('common.shop'),
        key: 'shop',
        dataIndex: 'shop',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.receiverPhone'),
        key: 'receiver',
        dataIndex: 'receiver',
        render: (value) => <span>{value.phone}</span>
    },
    {
        title: i18next.t('common.processedBy'),
        key: 'processedBy',
        dataIndex: 'processedBy',
        render: (value) => <span>{value.name}</span>
    },
    {
        title: i18next.t('common.amount'),
        key: 'origAmount',
        dataIndex: 'origAmount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.discount'),
        key: 'discount',
        dataIndex: 'discount',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.fee'),
        key: 'fees',
        dataIndex: 'fees',
        render: (value) => <span>{helper.toNumberFormat(value.creditFee)}</span>
    },
    {
        title: i18next.t('common.income'),
        key: 'netIn',
        dataIndex: 'netIn',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.outcome'),
        key: 'netOut',
        dataIndex: 'netOut',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.refund'),
        key: 'netRefunded',
        dataIndex: 'netRefunded',
        render: (value) => <span>{helper.toNumberFormat(value)}</span>
    },
    {
        title: i18next.t('common.status'),
        key: 'status',
        dataIndex: 'status',
        render: (value) => <Tag color={value === 'done' ? '#87d068' : '#b80606'}>{value}</Tag>
    },
    {
        title: i18next.t('common.time'),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    }
]
const statusList = [{ name: "done" }, { name: "refunded" }, { name: "pending" }, { name: "cancelled" }, { name: "failed" }, { name: "reversed" }, { name: "expired" }, { name: "refused" }, { name: "redeemed" }];

const TransHistory = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const transHistoryRedeucer = useSelector(state => state.transHistory);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    useEffect(() => {
        fetchData();

        const fetchDataForSearch = () => {
            dispatch(findServiceList());
            dispatch(findShopList({ merchantId: user.id }));
            dispatch(findSubmerchantList({ id: user.id }));
        }
        fetchDataForSearch();
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args, isExport) => {
        try {
            setLoading(true);
            if (args) {
                skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
                limit = parseInt(args.pageSize)
            }

            let objSearch = {}
            if (search.length) {
                search.forEach(item => {
                    objSearch[item.name[0]] = item.value
                })

                if (objSearch.createdAt) {
                    objSearch.startDate = moment(objSearch.createdAt[0]).format("YYYY-MM-DD")
                    objSearch.endDate = moment(objSearch.createdAt[1]).format("YYYY-MM-DD")
                    delete objSearch.createdAt
                }
            }

            const params = {
                ...objSearch,
                id: user.id,
                skip: skip,
                limit: limit
            }

            if (isExport) {
                let exported = await dispatch(exportTransactions({ ...params, limit: -1 })).unwrap();
                setLoading(false);
                window.open(exported.link, '_blank')
            }

            const result = await dispatch(findTransactions(params)).unwrap();

            setData(helper.assignOrderNumber(result.data, skip));
            setPagination({ ...pagination, total: result.total || 0 });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    let colsKey = columns.map(c => c.key);
    if (!colsKey.includes('action')) {
        columns.push({
            title: i18next.t('common.action'),
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Space size="middle" onClick={() => {
                    dispatch(detailTrans({ id: record.id }));
                    setShowDetail(true);
                }}>
                    <Button type="primary"><InfoCircleOutlined /> {i18next.t('common.detail')}</Button>
                </Space>
            )
        })
    }

    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.transHistory')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form form={form}
                                layout="vertical"
                                onFinish={value => fetchData()}
                                onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item name="txnCode" label={i18next.t('common.transCode')} >
                                            <Input placeholder={i18next.t('common.transCode')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="senderPhone" label={i18next.t('common.phone')} >
                                            <Input placeholder={i18next.t('common.phone')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="serviceId" label={i18next.t('common.transName')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.transName')}>
                                                {
                                                    transHistoryRedeucer.services.map((item, index) => <Select.Option key={`${index}_trans_name`} value={item.id}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="shop" label={i18next.t('common.shop')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.shop')}>
                                                {
                                                    transHistoryRedeucer.shops.map((item, index) => <Select.Option key={`${index}_shop`} value={item.id}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        <Form.Item name="submerchantId" label={i18next.t('common.subMerchant')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.subMerchant')}>
                                                {
                                                    transHistoryRedeucer.subMerchants.map((item, index) => <Select.Option key={`${index}_sub_merchant`} value={item.id}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="status" label={i18next.t('common.status')} >
                                            <Select allowClear={true}
                                                placeholder={i18next.t('common.status')}>
                                                {
                                                    statusList.map((item, index) => <Select.Option key={`${index}_status`} value={item.name}>{item.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="createdAt" label={i18next.t('common.createdAt')}>
                                            <RangePicker format={dateFormat} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={19}></Col>
                                    <Col span={5}>
                                        <Form.Item>
                                            <Button className="mr-2" icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                            <Button onClick={() => fetchData('', true)} icon={<DownloadOutlined />} type="default" htmlType="button">{i18next.t('common.export')}</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            <Modal title={i18next.t('common.transDetailInfo')} className="text-center"
                                visible={showDetail || Boolean(transHistoryRedeucer.detailTrans.name)} footer={null} className="modal-detail-transaction"
                                onCancel={() => {
                                    setShowDetail(false)
                                    dispatch(clearTransDetail({}))
                                }}>
                                <DetailTrans />
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                            <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                                pagination={pagination} onChange={changed => fetchData(changed)} scroll={{ x: 'max-content' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransHistory;