import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import config from '../../services/config';
import storage from '../../services/storage';

const initialState = {
    user: {},
    objCaptcha: {},
    isLogIn: false,
    showOTPForm: false,
    otpId: '',
    objUser: {},
    tokenMcPt: {},
    token: ''
};

export const clientGetCaptcha = createAsyncThunk(
    'clientGetCaptcha',
    async () => {
        try {
            return await api.getCaptcha();
        } catch (error) {
            console.log('clientGetCaptcha: ', error)
        }
    }
);

export const clientRequestLogin = createAsyncThunk(
    'clientRequestLogin', async (data, { dispatch }) => {
        try {
            let headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${config.THIRD_PARTY_TOKEN}`
            }
            return await api.login(data, headers)
        } catch (error) {
            dispatch(clientGetCaptcha());
        }
    }
);

export const clientVerifyLogin = createAsyncThunk(
    'clientVerifyLogin', async (data) => {
        try {
            let headers = {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${config.THIRD_PARTY_TOKEN}`
            }
            return await api.verifyLogin(data, headers)
        } catch (error) {
            console.log('clientVerifyLogin: ', error)
        }
    }
);


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        closeOTPPopup: (state) => {
            state.showOTPForm = false
            state.otpId = ''
        },
        userLogout: (state) => {
            state.user = {}
            state.objCaptcha = {}
            state.isLogIn = false
            state.showOTPForm = false
            state.otpId = ''
            state.objUser = {}
            state.tokenMcPt = {}
            state.token = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(clientGetCaptcha.fulfilled, (state, action) => {
                state.objCaptcha = action.payload;
            })
            .addCase(clientRequestLogin.fulfilled, (state, action) => {
                if (action.payload) {
                    if (action.payload.data.otp) {
                        state.showOTPForm = true
                        state.otpId = action.payload.data.otpId
                        storage.local.setItem('deviceId', action.payload.data.deviceId)
                    } else {
                        let { userInfo, tokenMcPt, token, deviceId } = action.payload.data;

                        storage.session.setItem('session', token)
                        storage.session.setItem('user', JSON.stringify(userInfo))
                        storage.session.setItem('tokenMcPt', tokenMcPt.token)
                        storage.local.setItem('deviceId', deviceId)

                        state.objUser = userInfo
                        state.tokenMcPt = tokenMcPt
                        state.token = token
                        state.objCaptcha = {}
                        state.isLogIn = true
                    }
                }
            })
            .addCase(clientVerifyLogin.fulfilled, (state, action) => {
                if (action.payload) {
                    let { userInfo, tokenMcPt, token, deviceId } = action.payload.data;

                    storage.session.setItem('session', token)
                    storage.session.setItem('user', JSON.stringify(userInfo))
                    storage.session.setItem('tokenMcPt', tokenMcPt.token)
                    storage.local.setItem('deviceId', deviceId)

                    state.objUser = userInfo
                    state.tokenMcPt = tokenMcPt
                    state.token = token
                    state.showOTPForm = false
                    state.otpId = ''
                    state.objCaptcha = {}
                    state.isLogIn = true
                }
            })
    },
})


export const { closeOTPPopup, userLogout } = userSlice.actions;

export default userSlice.reducer;