import React, { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Table } from 'antd';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
// import moment from 'moment';

// import helper from '../../services/helper';
// import { } from './dashboardSlice';

const { RangePicker } = DatePicker;


const ShopSaleReport = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        // try {
        //     setIsLoading(true);
        //     let search = {}

        //     if (date.length) {
        //         search.startDate = moment(date[0]).format('YYYY-MM-DD');
        //         search.endDate = moment(date[1]).format('YYYY-MM-DD');
        //     } else search = {}

        //     let result = await dispatch(shopSaleReport(search)).unwrap();

        //     setData(helper.assignOrderNumber(result.data, 0));
        //     setIsLoading(false);
        // } catch (error) {
        //     setIsLoading(false);
        // }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card__body">
                        <div className="row" style={{ borderBottom: '1px solid', paddingBottom: '10px' }}>
                            <Col md={8} sm={24}>
                                <label className="label">{i18next.t('common.shopSaleReport')}</label>
                            </Col>

                            <Col md={{ span: 14, offset: 2 }} sm={24}>
                                <Row>
                                    <Col md={21} >
                                        <RangePicker format={"YYYY-MM-DD"} onChange={e => setDate(e)} allowClear={true} />
                                    </Col>
                                    <Col md={1}>
                                        <SearchOutlined onClick={() => fetchData()} style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col>
                                    {/* <Col md={2}>
                                        <PrinterOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
                                    </Col> */}
                                </Row>
                            </Col>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopSaleReport;