import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Pagination, DatePicker, Input, Button, Form } from 'antd';
import { AndroidOutlined, AppleOutlined, WindowsOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18next from 'i18next';

import { removeDevice, fetchDeviceList } from './profileSlice';
import { logout } from '../../app/commonSlice';
import { userLogout } from '../login/loginSlice';
import storage from '../../services/storage';
import helper from '../../services/helper';
import Loading from '../loading';

const { RangePicker } = DatePicker;

const Devices = ({ user }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileReducer = useSelector(state => state.profile);
    const currentDevice = storage.local.getItem('deviceId');

    useEffect(() => {
        fetchData();
    }, [dispatch])


    var skip = 0, limit = 10;
    const fetchData = (args, search = {}) => {
        if (search.ip || search.lastLoginAt) {
            skip = 0;
            limit = 10;
            args = undefined
            if (search.lastLoginAt) {
                search.startDate = moment(search.lastLoginAt[0]).format("YYYY-MM-DD")
                search.endDate = moment(search.lastLoginAt[1]).format("YYYY-MM-DD")
                delete search.lastLoginAt
            }
        }

        if (args) {
            skip = (args.page - 1) * args.pageSize
            limit = args.pageSize
        }

        const params = { skip, limit, ...search };
        dispatch(fetchDeviceList(params));
    }

    const handleRemoveDevice = (device) => {
        let title = i18next.t('question.removeOtherDevice');
        if (currentDevice === device.deviceId) title = i18next.t('question.removeCurrDevice');

        helper.confirmWithRemark(title).then(confirm => {
            if (confirm) {
                dispatch(removeDevice({ deviceObjId: device.id, id: user.id, remark: confirm.value }));
                helper.toast('success', i18next.t('common.successfully'));
                if (currentDevice === device.deviceId) {
                    history.push('/login');
                    dispatch(userLogout());
                    dispatch(logout());
                    storage.session.clear();
                    storage.local.removeItem('deviceId');
                } else
                    fetchData();
            }
        });
    }

    return (
        <>
            {profileReducer.isLoading && <Loading />}
            <Col md={24} className="mb-3">
                <Row className="pull-right">
                    <Form form={form} layout="inline" onFinish={value => fetchData('', value)} >
                        <Form.Item name="ip" label={i18next.t('common.ip')}>
                            <Input placeholder={i18next.t('common.ip')} allowClear={true} />
                        </Form.Item>
                        <Form.Item name="lastLoginAt" label={i18next.t('common.lastLoginAt')} >
                            <RangePicker />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary"
                                htmlType="submit"
                                icon={<SearchOutlined />}>
                                {i18next.t('common.search')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Row>
            </Col>
            <Col md={24} className="label mb-3">{i18next.t('common.total')}: {profileReducer.objDevice?.total}</Col>
            {profileReducer.objDevice?.data?.map((device, index) => <Col span={12} className="mb-5" key={index}>
                <Row className="device">
                    <Col md={3} sm={24} className="container-device-logo">
                        {device.os === 'Android' && <AndroidOutlined className="device-logo" />}
                        {(device.os === 'Mac OS' || device.os === 'mac') && <AppleOutlined className="device-logo" />}
                        {device.os === 'Windows' && <WindowsOutlined className="device-logo" />}
                    </Col>
                    <Col md={21} sm={24}>
                        <CloseCircleOutlined className="icon-remove-device" onClick={() => handleRemoveDevice(device)} />
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.deviceId')}:</Col>
                            <Col md={15}>{device.deviceId}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.os')}:</Col>
                            <Col md={16}>{device.os}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.browser')}:</Col>
                            <Col md={16}>{device.browser || '-'}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.channel')}:</Col>
                            <Col md={16}>{device.channel || '-'}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.ip')}:</Col>
                            <Col md={16}>{device.ip || '-'}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.lat_long')}:</Col>
                            <Col md={16}>{`${device.lat},${device.long}` || '-'}</Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={8} className="label">{i18next.t('common.lastLoginAt')}:</Col>
                            <Col md={16}>{moment(device.lastLoginAt).format('YYYY-MM-DD HH:mm') || '-'}</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>)}
            <Col md={24}><Pagination responsive={true} defaultPageSize={limit} onChange={(page, pageSize) => fetchData({ page, pageSize })} total={profileReducer.objDevice?.total} /></Col>
        </>
    )
}

export default Devices;
