import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button, Checkbox, Tabs, Modal, Select, Form, Input, Tree } from 'antd';
import { MobileOutlined, LaptopOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { cloneDeep, clone } from 'lodash';

import helper from '../../services/helper';
import storage from '../../services/storage';
import Loading from '../loading';
import {
    findClientRoles, getPermissionForMobile, getPermissionForWeb,
    createUserRole, updateClientRolePerms, getClientMenu, merchantMenu
} from './roleSlice';


const Role = () => {
    const dispatch = useDispatch();
    const roleReducer = useSelector(state => state.role);
    const currentLang = storage.local.getItem('lang') || 'en';

    const [roleSelected, setRoleSelected] = useState({ webPermissions: { menus: [], permissions: [] }, permissions: [], isInitital: true });
    const [isModify, setIsModify] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [menuHvSubMenu, setMenuHvSubMenu] = useState([]);
    const [menuHvntSubMenu, setMenuHvntSubMenu] = useState([]);

    useEffect(() => {
        fetchData();
        dispatch(merchantMenu());
        dispatch(getPermissionForWeb());
        dispatch(getPermissionForMobile());
        dispatch(findClientRoles());
    }, [dispatch])

    const handleChangeMobilePermission = (checked, record) => {
        let dataCopy = cloneDeep(roleSelected);
        if (!dataCopy.permissions) dataCopy.permissions = []

        if (checked) dataCopy.permissions.push(record.id)
        else dataCopy.permissions = dataCopy.permissions.filter(w => w !== record.id)

        setRoleSelected(dataCopy);
        if (!isModify) setIsModify(true);
    }

    // const handleChangeWebPermission = (checked, record) => {
    //     let dataCopy = cloneDeep(roleSelected);
    //     if (!dataCopy.webPermissions) dataCopy.webPermissions = []

    //     if (checked) dataCopy.webPermissions.push(record.id)
    //     else dataCopy.webPermissions = dataCopy.webPermissions.filter(w => w !== record.id)

    //     setRoleSelected(dataCopy);
    //     if (!isModify) setIsModify(true);
    // }

    const handleUpdatePermission = async () => {
        if (isModify) {
            let dataCopyMenuHvSub = clone(menuHvSubMenu);
            let dataCopyMenuHvntSub = clone(menuHvntSubMenu);

            let checkeds = [...dataCopyMenuHvSub.map(i => i.checked), ...dataCopyMenuHvntSub.map(i => i.checked)].join().split(',').filter(i => i)

            let allMenuIds = roleReducer.allMerchantMenu.map(i => i.id);
            let allWePerIds = roleReducer.webPermissions.map(i => i.id);
            let webPermissions = { menus: [], permissions: [] }

            webPermissions.menus = allMenuIds.filter(i => checkeds.includes(i))
            webPermissions.permissions = allWePerIds.filter(i => checkeds.includes(i))

            let paramsToSend = {
                id: roleSelected.id,
                permissions: roleSelected.permissions,
                webPermissions: webPermissions
            }
            await dispatch(updateClientRolePerms(paramsToSend)).unwrap();
            helper.toast('success', i18next.t('common.successfully'));
            setIsModify(false);
        }
    }

    const fetchData = async () => {
        try {
            let result = await dispatch(getClientMenu()).unwrap();

            let menuHvSub = result.data.filter(i => i.subMenu);
            let menuHvntSub = result.data.filter(i => !i.subMenu);
            let arr1 = [], arr2 = [];

            menuHvSub.map((item, index) => {
                let children = []
                item.subMenu.forEach(i => {
                    let subChild = []
                    i.permissions.forEach(p => {
                        subChild.push({
                            key: p.id,
                            title: p.name[currentLang]
                        })
                    })

                    children.push({
                        key: i.id,
                        title: i.name[currentLang],
                        children: subChild
                    })
                })
                arr1.push([{
                    key: item.id,
                    title: item.name[currentLang],
                    children: children
                }])
                arr1[index].expanded = [item.id, ...children.map(c => c.value)]
                arr1[index].checked = []
            })
            menuHvntSub.map((item, index) => {
                let children = []
                item.permissions.map(p => {
                    children.push({
                        key: p.id,
                        title: p.name[currentLang]
                    })
                })
                arr2.push([{
                    key: item.id,
                    title: item.name[currentLang],
                    children: children
                }])
                arr2[index].expanded = [item.id, ...children.map(c => c.value)]
                arr2[index].checked = []
            })

            // debugger
            setMenuHvSubMenu(arr1);
            setMenuHvntSubMenu(arr2);
        } catch (error) {

        }
    }
    const handleChangeRole = (role) => {
        let roleCopy = cloneDeep(role)
        if (!roleCopy.webPermissions.menus) roleCopy.webPermissions.menus = []
        if (!roleCopy.webPermissions.permissions) roleCopy.webPermissions.permissions = []

        let dataCopyMenuHvSub = clone(menuHvSubMenu);
        let dataCopyMenuHvntSub = clone(menuHvntSubMenu);

        dataCopyMenuHvSub.forEach((item, index) => {
            item.checked = [...roleCopy.webPermissions.menus, ...roleCopy.webPermissions.permissions]
        })
        dataCopyMenuHvntSub.forEach((item, index) => {
            item.checked = [...roleCopy.webPermissions.menus, ...roleCopy.webPermissions.permissions]
        })
        // debugger
        setMenuHvSubMenu(dataCopyMenuHvSub);
        setMenuHvntSubMenu(dataCopyMenuHvntSub);
        setRoleSelected(roleCopy);
    }

    return (
        <>
            {roleReducer.isLoading && <Loading />}
            <h2 className="page-header">{i18next.t('menu.rolePermission')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body" style={{ minHeight: '950px' }}>
                            {/* <Col span={24}> */}
                            <Row>
                                <Col md={8}>
                                    <label className="label">{i18next.t('common.role')}</label>
                                    <Select style={{ width: '100%' }} onChange={e => {
                                        setIsModify(false);
                                        handleChangeRole(roleReducer.roles.find(r => r.id == e));
                                    }}>
                                        {roleReducer.roles.map((item, index) => <Select.Option key={`role_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                    </Select>
                                    <br />
                                    <br />
                                    {roleSelected.isDefault && <label className="label">{i18next.t('common.roleNote')}</label>}
                                </Col>
                                <Col md={4} style={{ marginTop: '22px' }}>
                                    <Button onClick={() => setShowModal(true)} type="primary" icon={<PlusCircleOutlined />}>{i18next.t('common.createUserRole')}</Button>
                                </Col>
                                <Modal visible={showModal} footer={null} onCancel={() => setShowModal(false)} title={i18next.t('common.createUserRole')}>
                                    <Form
                                        labelAlign="left"
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        onFinish={async (value) => {
                                            await dispatch(createUserRole(value)).unwrap();
                                            helper.toast('success', i18next.t('common.successfully'));
                                            dispatch(findClientRoles());
                                            setShowModal(false);
                                        }}>
                                        {roleReducer.isLoading && <Loading />}
                                        <Form.Item
                                            label={i18next.t('common.name')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: i18next.t('required_desc.name'),
                                                },
                                            ]} >
                                            <Input placeholder={i18next.t('common.name')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={i18next.t('common.desc')}
                                            name="desc"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: i18next.t('required_desc.desc'),
                                                },
                                            ]}  >
                                            <Input placeholder={i18next.t('common.desc')} />
                                        </Form.Item>


                                        <Form.Item wrapperCol={{ offset: 8, span: 16 }} >
                                            <Button type="primary" htmlType="submit">
                                                {i18next.t('common.save')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </Row>
                            <Row className="mt-5">
                                <Col md={24}>
                                    <Button onClick={() => handleUpdatePermission()} disabled={!isModify} className="mb-2" type="primary" icon={<SaveOutlined />}>{i18next.t('common.updatePermission')}</Button>
                                    <Tabs defaultActiveKey="forMobileApp" type="card" size="large">
                                        <Tabs.TabPane tab={<>
                                            <MobileOutlined />
                                            <span>{i18next.t('common.forMobileApp')}</span>
                                        </>} key="forMobileApp">
                                            <Row>
                                                {roleReducer.mobilePermission.map((item, index) => <Col className="mb-3" md={8} key={`mobile_permission_col_${index + 1}`}>
                                                    <Checkbox disabled={roleSelected.isDefault || roleSelected.isInitital} onChange={e => handleChangeMobilePermission(e.target.checked, item)} checked={Boolean(roleSelected.permissions.find(p => p === item.id))} id={`mobile_permission_checkbox_${index + 1}`} key={`mobile_permission_checkbox_${index + 1}`} />
                                                    &nbsp; &nbsp;
                                                    <label className="label_checkbox_permission" htmlFor={`mobile_permission_checkbox_${index + 1}`} key={`mobile_permission_label_${index + 1}`}>{item.name[currentLang]}</label>
                                                </Col>)}
                                            </Row>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab={<>
                                            <LaptopOutlined />
                                            <span>{i18next.t('common.forWebPortal')}</span>
                                        </>} key="forWebPortal">
                                            <Row>
                                                {/* {roleReducer.webPermissions.map((item, index) => <Col className="mb-3" md={8} key={`web_permission_col_${index + 1}`}>
                                                    <Checkbox disabled={roleSelected.isDefault || roleSelected.isInitital} onChange={e => handleChangeWebPermission(e.target.checked, item)} checked={Boolean(roleSelected.webPermissions.find(p => p === item.id))} id={`web_permission_checkbox_${index + 1}`} key={`web_permission_checkbox_${index + 1}`} />
                                                    &nbsp; &nbsp;
                                                    <label className={`label_checkbox_permission ${item.type === 'menu_action' ? 'text-red' : ''}`} htmlFor={`web_permission_checkbox_${index + 1}`} key={`web_permission_label_${index + 1}`}>{item.name[currentLang]}</label>
                                                </Col>)} */}
                                                {menuHvSubMenu.map((nodes, index) => {
                                                    return (
                                                        <Col className="mb-3" md={8} key={`menuHvSubMenu${index + 1}`}>
                                                            <Tree
                                                                disabled={roleSelected.isDefault || roleSelected.isInitital}
                                                                checkable
                                                                onExpand={expanded => {
                                                                    let dataCopy = clone(menuHvSubMenu);
                                                                    nodes.expanded = expanded;
                                                                    dataCopy[index] = nodes;
                                                                    setMenuHvSubMenu(dataCopy);
                                                                }}
                                                                expandedKeys={nodes.expanded || []}
                                                                autoExpandParent={true}
                                                                onCheck={checked => {
                                                                    let dataCopy = clone(menuHvSubMenu);
                                                                    nodes.checked = checked;
                                                                    dataCopy[index] = nodes;
                                                                    setMenuHvSubMenu(dataCopy);
                                                                    setIsModify(true);
                                                                }}
                                                                checkedKeys={nodes.checked || []}
                                                                // onSelect={onSelect}
                                                                // selectedKeys={selectedKeys}
                                                                treeData={nodes}
                                                            />
                                                        </Col>
                                                    )
                                                })}
                                                {menuHvntSubMenu.map((nodes, index) => {
                                                    return (<Col className="mb-3" md={8} key={`menuHvntSubMenu${index + 1}`}>
                                                        <Tree
                                                            disabled={roleSelected.isDefault || roleSelected.isInitital}
                                                            checkable
                                                            onExpand={expanded => {
                                                                let dataCopy = clone(menuHvntSubMenu);
                                                                nodes.expanded = expanded;
                                                                dataCopy[index] = nodes;
                                                                setMenuHvntSubMenu(dataCopy);
                                                            }}
                                                            expandedKeys={nodes.expanded || []}
                                                            autoExpandParent={true}
                                                            onCheck={checked => {
                                                                let dataCopy = clone(menuHvntSubMenu);
                                                                nodes.checked = checked;
                                                                dataCopy[index] = nodes;
                                                                setMenuHvntSubMenu(dataCopy);
                                                                setIsModify(true);
                                                            }}
                                                            checkedKeys={nodes.checked || []}
                                                            // onSelect={onSelect}
                                                            // selectedKeys={selectedKeys}
                                                            treeData={nodes}
                                                        />
                                                    </Col>)
                                                })}

                                            </Row>
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                            {/* </Col> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Role;