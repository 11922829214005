import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Input, Select, Form, Button, DatePicker, Radio, Checkbox } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import moment from 'moment';

import Image from '../../components/image';
import constant from '../../services/constant';
import helper from '../../services/helper';
import storage from '../../services/storage';
import {
    getCountry, getProvinceByCountry, getDistrictByProvince, getTownshipByDistrict,
    clearProvince, clearDistrict, clearTownship, findNricConfig, getS3Url,
    uploadImage
} from '../../app/commonSlice';
import { clientRolesList, getSubmerchantDetail, createSubmerchant, updateSubmerchant, setLoading } from './employeeSlice';
import { findShops } from '../shop/shopSlice';
import Loading from '../loading';
import QRView from '../../components/qrcodeView';

var requiredFields = [
    { fieldName: 'avatar', errorMsg: 'common.avatar', isChoose: true },
    { fieldName: 'phone', errorMsg: 'common.subMerchantPhone' },
    { fieldName: 'ownerName', errorMsg: 'common.subMerchantName' },
    { fieldName: 'name', errorMsg: 'common.subMerchantBusinessName' },
    { fieldName: 'birth', errorMsg: 'common.dob', isChoose: true },
    { fieldName: 'gender', errorMsg: 'common.gender', isChoose: true },
    { fieldName: 'maritalStatus', errorMsg: 'common.maritalStatus', isChoose: true },
    { fieldName: 'fatherName', errorMsg: 'common.fatherName' },
    { fieldName: 'motherName', errorMsg: 'common.motherName' },
    { fieldName: 'roles', errorMsg: 'common.role', isChoose: true },
    { fieldName: 'listShop', errorMsg: 'common.shop', isChoose: true },
    { fieldName: 'country', errorMsg: 'common.country', isChoose: true },
    { fieldName: 'province', errorMsg: 'common.region', isChoose: true },
    { fieldName: 'district', errorMsg: 'common.district', isChoose: true },
    { fieldName: 'township', errorMsg: 'common.township', isChoose: true },
    { fieldName: 'address', errorMsg: 'common.address' },
]

const EmployeeForm = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = JSON.parse(storage.session.getItem('user'));
    const commonReducer = useSelector(state => state.common);
    const employeeReducer = useSelector(state => state.employee);
    const shopReducer = useSelector(state => state.shop);
    const [form] = Form.useForm();
    const [nrcTspCodes, setNricTspCodes] = useState([]);
    const [kycType, setKycType] = useState('newNric');
    const [objImage, setObjImage] = useState({});
    const [allShop, setAllShop] = useState(false);
    const [listShop, setListShop] = useState([]);
    const [parameter, setParameter] = useState({});
    const [objNewNRIC, setObjNewNRIC] = useState({});
    const [objPassport, setObjPassport] = useState({});
    const [oldNric, setOldNric] = useState('');
    const [mode, setMode] = useState('create');
    const [shopSlt, setShopSlt] = useState({ qrcode: '', id: '' });

    useEffect(() => {
        dispatch(findNricConfig());
        dispatch(getCountry());
        dispatch(getS3Url());
        dispatch(clientRolesList());
        dispatch(findShops({ status: 'active', skip: 0, limit: 50 }));

        if (props.match && props.match.params && props.match.params.subMerchantId) {
            detailSubMerchant(props.match.params.subMerchantId);
            setMode('update')
        }
    }, [dispatch])

    const detailSubMerchant = async (subMerchantId) => {
        let result = await dispatch(getSubmerchantDetail({ id: subMerchantId })).unwrap();
        let { kycType, passportInfo, avatar, nricImages = {}, birth, phone, regName, name, limitShop,
            roles, gender, maritalStatus, ownerInfo, oldNric, nric, email, nationality, shopList, qrcode
        } = result.data;
        let images = {}
        images.avatar = avatar

        if (kycType === 'passport') {
            let expireDate = moment(passportInfo.expireDate)
            setObjPassport({ ...passportInfo, expireDate });
            images.passportImage = passportInfo.image
        } else {
            images.nricFront = nricImages.front
            images.nricBack = nricImages.back
            if (kycType === 'newNric') {
                setObjNewNRIC(nric)
            } else {
                setOldNric(oldNric)
            }
        }

        setKycType(kycType);
        setObjImage(images);

        birth = moment(birth);

        dispatch(getProvinceByCountry({ countryId: ownerInfo.country.id }));
        dispatch(getDistrictByProvince({ provinceId: ownerInfo.province.id }));
        dispatch(getTownshipByDistrict({ districtId: ownerInfo.district.id }));

        if (limitShop) {
            setAllShop(!true)
        }

        setListShop(shopList.map(i => i.id));
        setShopSlt(shopList[0])

        const objParams = {
            kycType, birth, phone, ownerName: name,
            name: regName, roles, gender: helper.capitalizeFirstLetter(gender),
            maritalStatus: helper.capitalizeFirstLetter(maritalStatus),
            email, nationality, fatherName: ownerInfo.fatherName,
            motherName: ownerInfo.motherName,
            country: ownerInfo.country.id, province: ownerInfo.province.id,
            district: ownerInfo.district.id, township: ownerInfo.township.id,
            address: ownerInfo.address, shopList
        }
        setParameter(objParams)
        form.setFieldsValue(objParams)
    }

    const handleGetCropImage = (key, croppedImageUrl) => {
        setObjImage({ ...objImage, ...{ [key]: croppedImageUrl } });
    }

    const submitForm = async () => {
        const args = { ...parameter, ...objImage, ...{ objNewNRIC }, ...{ objPassport }, listShop, kycType, oldNric }
        let validate = helper.validateFields(requiredFields, args);
        if (validate) return false;

        if (kycType === 'newNric') {
            let requiredFieldsMore = [
                { fieldName: 'objNewNRIC.code', errorMsg: 'common.stateDivisionCode', isChoose: true },
                { fieldName: 'objNewNRIC.name', errorMsg: 'common.townshipCode', isChoose: true },
                { fieldName: 'objNewNRIC.type', errorMsg: 'common.nricType', isChoose: true },
                { fieldName: 'objNewNRIC.number', errorMsg: 'common.nricNumber' },
                { fieldName: 'nricFront', errorMsg: 'common.nricFront', isChoose: true },
                { fieldName: 'nricBack', errorMsg: 'common.nricBack', isChoose: true }
            ]

            let validate = helper.validateFields(requiredFieldsMore, args);
            if (validate) return false;
        } else if (kycType === 'oldNric') {
            let requiredFieldsMore = [
                { fieldName: 'oldNric', errorMsg: 'common.nricNumber' },
                { fieldName: 'nricFront', errorMsg: 'common.nricFront', isChoose: true },
                { fieldName: 'nricBack', errorMsg: 'common.nricBack', isChoose: true }
            ]

            let validate = helper.validateFields(requiredFieldsMore, args);
            if (validate) return false;
        } else if (kycType === 'passport') {
            let requiredFieldsMore = [
                { fieldName: 'objPassport.countryCode', errorMsg: 'common.country', isChoose: true },
                { fieldName: 'objPassport.passportNo', errorMsg: 'common.passportNo' },
                { fieldName: 'objPassport.expireDate', errorMsg: 'common.expiredDate', isChoose: true },
                { fieldName: 'passportImage', errorMsg: 'common.passportImage', isChoose: true }
            ]

            let validate = helper.validateFields(requiredFieldsMore, args);
            if (validate) return false;
        }


        for (let i = 0; i < Object.keys(objImage).length; i++) {
            if (objImage[Object.keys(objImage)[i]].includes('blob')) {
                let result = await dispatch(uploadImage({ file: objImage[Object.keys(objImage)[i]], subInfo: JSON.stringify({ model: 'SUBMERCHANT' }), isUpload: true })).unwrap();
                let { err, data } = result.data;

                if (err && err === 200) {
                    args[Object.keys(objImage)[i]] = data
                } else {
                    helper.toast('error', i18next.t('common.internalServerError'))
                }
            }
        }

        // console.log(args)
        const paramsToSend = {
            "limitShop": !allShop,
            "avatar": args.avatar,
            "birth": moment(args.birth).format('YYYY-MM-DD'),
            "email": args.email,
            "gender": args.gender,
            "kycType": kycType,
            "listShop": listShop,
            "maritalStatus": args.maritalStatus,
            "name": args.ownerName,
            "nationality": args.nationality,
            "nric": {
                "code": objNewNRIC.code,
                "name": objNewNRIC.name,
                "number": objNewNRIC.number,
                "type": objNewNRIC.type
            },
            "nricBack": args.nricBack,
            "nricFront": args.nricFront,
            "oldNric": oldNric,
            "ownerInfo": {
                "address": args.address,
                "country": args.country,
                "district": args.district,
                "fatherName": args.fatherName,
                "motherName": args.motherName,
                "province": args.province,
                "township": args.township
            },
            "ownerName": args.name,
            "passportImage": args.passportImage,
            "passportInfo": objPassport,
            "phone": args.phone,
            "roles": args.roles,
            "merchantId": user.id
        }

        dispatch(setLoading(true))
        if (mode === 'create') {
            await dispatch(createSubmerchant(paramsToSend)).unwrap();
        } else {
            paramsToSend.submerchantId = props.match.params.subMerchantId
            await dispatch(updateSubmerchant(paramsToSend)).unwrap();
        }
        dispatch(setLoading(false))
        helper.toast('success', i18next.t('common.successfully'));
        history.push('/submerchants');
    }

    const handleChangeStateDivisionCode = (e) => {
        let obj = commonReducer.nricConfig.nrcTspCodes.find(i => i.stateDiviNo == e)
        if (obj) setNricTspCodes(obj.townships)
        setObjNewNRIC({ ...objNewNRIC, ...{ 'code': e } })
    }

    return (
        <div>
            {employeeReducer.isLoading && <Loading />}
            <h2 className="page-header">{mode === 'create' ? i18next.t('common.createSubMerchant') : i18next.t('common.updateSubMerchant')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form
                                initialValues={{ kycType: 'newNric' }}
                                form={form}
                                name="basic"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 14 }}
                                onValuesChange={(changedValues, allValues) => {
                                    setParameter(allValues)
                                }}>
                                <fieldset>
                                    <legend>{i18next.t('common.basicInfo')}</legend>
                                    <Row>
                                        <Col md={6} style={{ marginTop: '15px' }}>
                                            <Row className="ml-3">
                                                <Image s3Url={commonReducer.s3Url} imgId={"avatar"}
                                                    defaultImg={objImage.avatar}
                                                    ratio={1 / 1}
                                                    mxWidth={300}
                                                    mxHeight={300}
                                                    getCroppedImage={val => handleGetCropImage('avatar', val)} />
                                                {/* <label className="label-required label-required-none-after">{i18next.t('common.avatar')}</label> */}
                                            </Row>
                                            <Row>
                                                {mode === 'update' && <>
                                                    <Row className="mt-5">
                                                        <Select value={shopSlt.id} style={{ width: '70%' }} onChange={e => {
                                                            let shop = parameter.shopList.find(i => i.id === e);
                                                            setShopSlt(shop);
                                                        }}>
                                                            {(parameter.shopList || []).map((item, index) => <Select.Option value={item.id} key={index + 10}>{item.name}</Select.Option>)}
                                                        </Select>
                                                        {shopSlt.qrcode && <QRView value={shopSlt.qrcode} subInfo={{ name: `${parameter.name} - ${shopSlt.name}` }} size={150} />}
                                                    </Row>
                                                </>}
                                            </Row>
                                        </Col>
                                        <Col md={18}>
                                            <Form.Item name="phone" label={i18next.t('common.subMerchantPhone')} required={true}>
                                                <Input disabled={Boolean(mode === 'update')} type="tel" placeholder={i18next.t('common.subMerchantPhone')} />
                                            </Form.Item>

                                            <Form.Item name="ownerName" label={i18next.t('common.subMerchantName')} required={true}>
                                                <Input placeholder={i18next.t('common.subMerchantName')} />
                                            </Form.Item>

                                            <Form.Item name="name" label={i18next.t('common.subMerchantBusinessName')} required={true}>
                                                <Input placeholder={i18next.t('common.subMerchantBusinessName')} />
                                            </Form.Item>

                                            <Form.Item name="birth" label={i18next.t('common.dob')} required={true}>
                                                <DatePicker style={{ width: '100%' }} placeholder={i18next.t('common.dob')} />
                                            </Form.Item>

                                            <Form.Item name="gender" label={i18next.t('common.gender')} required={true}>
                                                <Select placeholder={i18next.t('common.gender')}>
                                                    {constant.genders.map((item, index) => <Select.Option key={`gender_${index}`} value={item}>{item}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="maritalStatus" label={i18next.t('common.maritalStatus')} required={true}>
                                                <Select placeholder={i18next.t('common.maritalStatus')}>
                                                    {constant.maritalStatus.map((item, index) => <Select.Option key={`marital_status_${index}`} value={item}>{item}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="fatherName" label={i18next.t('common.fatherName')} required={true}>
                                                <Input placeholder={i18next.t('common.fatherName')} />
                                            </Form.Item>

                                            <Form.Item name="motherName" label={i18next.t('common.motherName')} required={true}>
                                                <Input placeholder={i18next.t('common.motherName')} />
                                            </Form.Item>

                                            <Form.Item name="email" label={i18next.t('common.email')}>
                                                <Input type="email" placeholder={i18next.t('common.email')} />
                                            </Form.Item>

                                            <Form.Item name="nationality" label={i18next.t('common.nationality')}>
                                                <Input placeholder={i18next.t('common.nationality')} />
                                            </Form.Item>

                                            <Form.Item name="roles" label={i18next.t('common.role')} required={true}>
                                                <Select placeholder={i18next.t('common.role')}>
                                                    {employeeReducer.roles.map((item, index) => <Select.Option key={`role_${index}`} value={item.code}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="listShop" label={i18next.t('common.shop')} required={true}>
                                                <Select value={listShop} onChange={e => setListShop(e)} disabled={allShop} mode="multiple" placeholder={i18next.t('common.shop')}>
                                                    {shopReducer.data.map((item, index) => <Select.Option key={`listShop_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                                <Checkbox checked={allShop} onChange={e => {
                                                    setAllShop(e.target.checked)
                                                    if (e.target.checked) setListShop([])
                                                }}>{i18next.t('common.allShop')}</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </fieldset>

                                <fieldset>
                                    <legend>{i18next.t('common.residenceAddess')}</legend>
                                    <Row>
                                        <Col md={16}>
                                            <Form.Item name="country" label={i18next.t('common.country')} required={true}>
                                                <Select placeholder={i18next.t('common.country')} allowClear={true}
                                                    onChange={e => e ? dispatch(getProvinceByCountry({ countryId: e })) : dispatch(clearProvince())}>
                                                    {commonReducer.countries.map((item, index) => <Select.Option key={`country_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="province" label={i18next.t('common.region')} required={true}>
                                                <Select placeholder={i18next.t('common.region')} allowClear={true}
                                                    onChange={e => e ? dispatch(getDistrictByProvince({ provinceId: e })) : dispatch(clearDistrict())}>
                                                    {commonReducer.provinces.map((item, index) => <Select.Option key={`region_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="district" label={i18next.t('common.district')} required={true}>
                                                <Select placeholder={i18next.t('common.district')} allowClear={true}
                                                    onChange={e => e ? dispatch(getTownshipByDistrict({ districtId: e })) : dispatch(clearTownship())}>
                                                    {commonReducer.districts.map((item, index) => <Select.Option key={`district_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="township" label={i18next.t('common.township')} required={true}>
                                                <Select placeholder={i18next.t('common.township')} allowClear={true}>
                                                    {commonReducer.townships.map((item, index) => <Select.Option key={`township_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="address" label={i18next.t('common.address')} required={true}>
                                                <Input.TextArea placeholder={i18next.t('common.address')} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </fieldset>

                                <fieldset>
                                    <legend>{i18next.t('common.kycInfo')}</legend>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item name="kycType" label={i18next.t('common.kycType')} required={true}>
                                                <Radio.Group value={kycType} onChange={e => setKycType(e.target.value)}>
                                                    {constant.kycTypes.map((item, index) => <Radio style={{ marginRight: '100px' }} key={`kyc_type_${index}`} value={item} >{i18next.t(`common.${item}`)}</Radio>)}
                                                </Radio.Group>
                                            </Form.Item>

                                            {kycType === 'newNric' && <Row >
                                                <Col md={4} className="label-required">{i18next.t('common.nricNumber')}</Col>
                                                <Col md={20}>
                                                    <Select value={objNewNRIC.code} style={{ width: '200px' }} className="mr-3" placeholder={i18next.t('common.stateDivisionCode')}
                                                        onChange={e => handleChangeStateDivisionCode(e)}>
                                                        {commonReducer.nricConfig.nrcStDivCodes.map((item, index) => <Select.Option key={'stateDivisionCode_' + index} value={item.id}>{item.id}</Select.Option>)}
                                                    </Select>

                                                    <Select value={objNewNRIC.name} onChange={e => setObjNewNRIC({ ...objNewNRIC, ...{ 'name': e } })} style={{ width: '160px' }} className="mr-3" placeholder={i18next.t('common.townshipCode')}>
                                                        {nrcTspCodes.map((item, index) => <Select.Option key={'townshipCode_' + index} value={item}>{item}</Select.Option>)}
                                                    </Select>

                                                    <Select value={objNewNRIC.type} onChange={e => setObjNewNRIC({ ...objNewNRIC, ...{ 'type': e } })} style={{ width: '160px' }} className="mr-3" placeholder={i18next.t('common.nricType')}>
                                                        {commonReducer.nricConfig.nrcTypes.map((item, index) => <Select.Option key={'nricType_' + index} value={item.id}>{item.id}</Select.Option>)}
                                                    </Select>

                                                    <Input value={objNewNRIC.number} onChange={e => setObjNewNRIC({ ...objNewNRIC, ...{ 'number': e.target.value } })} placeholder={i18next.t('common.nricNumber')} style={{ width: '350px' }} />
                                                </Col>
                                            </Row>}
                                            {kycType === 'oldNric' && <Row>
                                                <Col md={4} className="label-required">{i18next.t('common.nricNumber')}</Col>
                                                <Col md={20}>
                                                    <Input value={oldNric} onChange={e => setOldNric(e.target.value)} placeholder={i18next.t('common.nricNumber')} style={{ width: '600px' }} />
                                                </Col>
                                            </Row>}
                                            {(kycType === 'newNric' || kycType === 'oldNric') && <Row>
                                                <Col md={4}></Col>
                                                <Col md={20}>
                                                    <Row style={{ marginTop: '15px' }}>
                                                        <Col md={12}>
                                                            <Image s3Url={commonReducer.s3Url} imgId={"nricFront"}
                                                                defaultImg={objImage.nricFront}
                                                                ratio={16 / 9}
                                                                mxWidth={1280}
                                                                mxHeight={720}
                                                                getCroppedImage={val => handleGetCropImage('nricFront', val)} />
                                                            <label className="label-required label-required-none-after">{i18next.t('common.nricFront')}</label>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Image s3Url={commonReducer.s3Url} imgId={"nricBack"}
                                                                defaultImg={objImage.nricBack}
                                                                ratio={16 / 9}
                                                                mxWidth={1280}
                                                                mxHeight={720}
                                                                getCroppedImage={val => handleGetCropImage('nricBack', val)} />
                                                            <label className="label-required label-required-none-after">{i18next.t('common.nricBack')}</label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>}
                                            {kycType === 'passport' && <Row>
                                                <Col md={4} className="label-required">{i18next.t('common.passport')}</Col>
                                                <Col md={20}>
                                                    <Select value={objPassport.countryCode} style={{ width: '250px' }} className="mr-3" placeholder={i18next.t('common.country')}
                                                        onChange={e => {
                                                            setObjPassport({ ...objPassport, ...{ 'country': e } })
                                                        }}>
                                                        {constant.countries.map((item, index) => <Select.Option key={'passport_country_' + index} value={item.Code}>{item.Code}-{item.Country}</Select.Option>)}
                                                    </Select>

                                                    <Input value={objPassport.passportNo} onChange={e => setObjPassport({ ...objPassport, ...{ 'passportNo': e.target.value } })} className="mr-3" placeholder={i18next.t('common.passportNo')} style={{ width: '250px' }} />

                                                    <DatePicker value={objPassport.expireDate} onChange={e => setObjPassport({ ...objPassport, ...{ 'expireDate': e } })} placeholder={i18next.t('common.expiredDate')} style={{ width: '250px' }} />
                                                    <Row style={{ marginTop: '15px' }}>
                                                        <Image s3Url={commonReducer.s3Url} imgId={"passportImage"}
                                                            defaultImg={objImage.passportImage}
                                                            ratio={16 / 9}
                                                            mxWidth={1280}
                                                            mxHeight={720}
                                                            getCroppedImage={val => handleGetCropImage('passportImage', val)} />
                                                    </Row>
                                                </Col>
                                            </Row>}
                                        </Col>
                                    </Row>
                                </fieldset>

                                <Row>
                                    <Col md={24} style={{ textAlign: 'center' }}>
                                        <Button className="mr-3" htmlType="button" onClick={() => history.push('/submerchants')}
                                            icon={<ArrowLeftOutlined />}>
                                            {i18next.t('common.back')}
                                        </Button>

                                        <Button type="primary" htmlType="button" onClick={() => submitForm()}
                                            icon={<SaveOutlined />}>
                                            {i18next.t('common.submit')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeForm;