// import { useHistory } from 'react-router-dom';
import axios from "axios";
import config from "./config";
import helper from "./helper";
import storage from "./storage";

const instance = axios.create({
  baseURL: config.host,
  timeout: 30000,
});

const request = {
  upload: (url, formData) => {
    let cf = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${storage.session.getItem("session")}`,
        Token: `Bearer ${storage.session.getItem("tokenMcPt") || ""}`,
        deviceId: storage.local.getItem("deviceId"),
      },
    };
    return new Promise((resolve, reject) => {
      instance
        .post(url, formData, cf)
        .then((rs) => resolve(rs))
        .catch((err) => reject(err));
    });
  },

  send: (url, data, headers, method = "post") => {
    if (!headers) {
      headers = {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${storage.session.getItem("session")}`,
        Token: `Bearer ${storage.session.getItem("tokenMcPt") || ""}`,
        deviceId: storage.local.getItem("deviceId"),
      };
    }

    return new Promise((resolve, reject) => {
      instance[method](url, data, { headers })
        .then((rs) => {
          console.log("rsss ", rs);
          if (rs.data.err === 0 || rs.data.code === 0 || rs.data.err === 200) {
            resolve(rs.data);
          } else {
            helper.toast("error", rs.data.message);
          }
        })
        .catch((err) => {
          helper.toast("error", err.response?.data?.message || err.message);
          if (err.response?.data.err === 8013) {
            storage.session.clear();
            window.location.href = "/#/login";
          }
          reject(err);
        });
    });
  },
};

export default request;
