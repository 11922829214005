import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Input, Button, Modal, Dropdown, Menu } from 'antd';
import { ReloadOutlined, UserOutlined, PhoneOutlined, LockOutlined, MailOutlined, AppstoreOutlined } from '@ant-design/icons';
import { osVersion, osName, browserVersion, browserName } from "react-device-detect";
import i18next from 'i18next';
import { translate } from 'react-i18next';

import storage from '../../services/storage';
import helper from '../../services/helper';
import { clientGetCaptcha, clientRequestLogin, clientVerifyLogin, closeOTPPopup } from './loginSlice';
import './index.css';
import webLogo from '../../assets/images/web-logo.png'
import loginImageInfo from '../../assets/images/login-image-info.png';

import Loading from '../loading';

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userReducer = useSelector(state => state.user);
    const currentLang = storage.local.getItem('i18nextLng');

    if (userReducer.isLogIn) {
        history.push("/dashboard")
    }

    const [otp, setOTP] = useState('');
    const [isDisable, setDisable] = useState(true);
    const [phone, setPhone] = useState();

    useEffect(() => {
        dispatch(clientGetCaptcha())
    }, [dispatch])

    // const isLogIn = Boolean(storage.session.getItem('session'));
    // if (!isLogIn) {
    //     history.push("/dashboard");
    // }

    if (!userReducer.objCaptcha || !userReducer.objCaptcha.id) {
        return (
            <div style={{ left: '50%', top: '50%', position: 'fixed' }}>
                <Loading />
            </div>
        )
    }

    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang)
        storage.local.setItem('lang', lang)
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={() => changeLanguage('en')}>
                {i18next.t('common.en')}
            </Menu.Item>
            <Menu.Item onClick={() => changeLanguage('my')}>
                {i18next.t('common.my')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Row className="container-login">
            <Col className="left">
                <Col className="col-form-login">
                    <Col className="panel">
                        <Col className="container-logo text-center">
                            <img src={webLogo} width={250} alt="Web Logo" />
                        </Col>

                        <Col className="container-form">
                            {/* <h3 style={{ fontWeight: 'bold', marginBottom: '20px' }}>Login</h3> */}
                            <Form layout="vertical" name="login-form"
                                onValuesChange={(changedValues, allValues) => {
                                    let { code, username, password, captchaText, remember } = allValues;
                                    setPhone(username);
                                    if (code && username && password && captchaText) setDisable(false)
                                    else setDisable(true)

                                    // if (remember) {
                                    //     storage.local.setItem('code', code)
                                    //     storage.local.setItem('username', username)
                                    //     storage.local.setItem('remember', remember)
                                    // } else {
                                    //     storage.local.removeItem('code')
                                    //     storage.local.removeItem('username')
                                    //     storage.local.removeItem('remember')
                                    // }
                                }}
                                onFinish={(value) => dispatch(clientRequestLogin({
                                    ...value,
                                    osVersion: osVersion, os: osName,
                                    browserVersion: browserVersion,
                                    browser: browserName,
                                    deviceId: storage.local.getItem('deviceId'),
                                    captchaId: userReducer.objCaptcha.id
                                }))}>
                                <Form.Item
                                    name="code"
                                    rules={[{ required: true, message: i18next.t('required_desc.userId') }]}>
                                    <Input placeholder={i18next.t('common.userId') + " *"} size="large" prefix={<UserOutlined />} style={{ borderRadius: '10px' }} />
                                </Form.Item>

                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: i18next.t('required_desc.phone') }]}>
                                    <Input placeholder={i18next.t('common.phone') + " *"} size="large" prefix={<PhoneOutlined />} style={{ borderRadius: '10px' }} />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: i18next.t('required_desc.password') }]}>
                                    <Input type="password" placeholder={i18next.t('common.password') + " *"} size="large" prefix={<LockOutlined />} style={{ borderRadius: '10px' }} />
                                </Form.Item>

                                <Row span={24}>
                                    <Col span={11} className="captcha" dangerouslySetInnerHTML={{ __html: userReducer.objCaptcha.data }} />
                                    <Col span={13} >
                                        <Form.Item
                                            name="captchaText" rules={[{ required: true, message: i18next.t('required_desc.captcha') }]}>
                                            <Input
                                                suffix={<ReloadOutlined onClick={() => dispatch(clientGetCaptcha())} />}
                                                placeholder={i18next.t('common.captcha') + " *"} size="large" style={{ borderRadius: '10px' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* <Form.Item
                                    name="remember"
                                    valuePropName="checked">
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item> */}

                                <br /><br />
                                <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                                    <Button disabled={isDisable} type="primary" htmlType="submit" style={{
                                        background: isDisable ? "#8A8B90" : "#4cb849",
                                        // border: "1px solid #4cb849",
                                        width: "100%",
                                        height: "40px",
                                        borderRadius: "10px",
                                        color: "white"
                                    }}>{i18next.t('common.submit')}</Button>
                                </Form.Item>
                            </Form>

                            <Modal title={i18next.t('common.otpVerification')} className="text-center"
                                visible={userReducer.showOTPForm}
                                onOk={() => {
                                    if (!otp) return helper.toast('error', i18next.t('required_desc.otp'));
                                    dispatch(clientVerifyLogin({ otp, otpId: userReducer.otpId, deviceId: storage.local.getItem('deviceId'), phone }))
                                }}
                                onCancel={() => dispatch(closeOTPPopup())}
                                okText="Confirm" >
                                <Form layout="vertical" name="otp-verification-form">
                                    <Form.Item
                                        label={i18next.t('common.getOtpViaSMS')}
                                        name="otp">
                                        <Input placeholder={i18next.t('common.otp')} value={otp} onChange={e => setOTP(e.target.value)} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Col>
                    </Col>
                </Col>
                <Col className="footer-login-left">
                    <Dropdown overlay={menu} placement="topRight">
                        <Button style={{ marginBottom: '-25px', marginLeft: '-10px', color: 'black', fontWeight: 'bold' }} type="link" icon={<AppstoreOutlined />}>{(currentLang === 'en-US' || currentLang === 'en') ? i18next.t('common.en') : i18next.t('common.my')}</Button>
                    </Dropdown>
                </Col>
                <Col className="footer-login-right">
                    <label htmlFor="" className="label"><MailOutlined />&nbsp;hello@Asyst.com</label>
                    {/* <label htmlFor="" className="label"><PhoneOutlined />&nbsp; 12344321</label> */}
                </Col>
            </Col>
            <Col className="right">
                <Col className="container-info">
                    <h1>{i18next.t("common.welcomeToAsyst")}</h1>
                    <p>{i18next.t("common.loginDesc")}</p>
                </Col>
                <Col className="container-login-right-image-1">
                    <img src={loginImageInfo} alt="login-image-info" />
                </Col>
            </Col>
        </Row>
    )
}

export default translate('common')(Login);