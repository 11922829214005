import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Input, Select, Form, Button, Tooltip, Radio, Checkbox, Switch } from 'antd';
import { ArrowLeftOutlined, SaveOutlined, PlusSquareOutlined, CloseSquareOutlined, InfoCircleFilled } from '@ant-design/icons';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';

import Image from '../../components/image';
import constant from '../../services/constant';
import helper from '../../services/helper';
import storage from '../../services/storage';
import {
    getCountry, getProvinceByCountry, getDistrictByProvince, getTownshipByDistrict,
    clearProvince, clearDistrict, clearTownship, getS3Url, uploadImage
} from '../../app/commonSlice';
import { getShopDetail } from './shopSlice';
import { createShop, updateShop, setLoading } from '../shop/shopSlice';
import Loading from '../loading';
import QRView from '../../components/qrcodeView';

var requiredFields = [
    { fieldName: 'name', errorMsg: 'common.name' },
    { fieldName: 'shortName', errorMsg: 'common.shortName' },
    { fieldName: 'country', errorMsg: 'common.country', isChoose: true },
    { fieldName: 'province', errorMsg: 'common.region', isChoose: true },
    { fieldName: 'district', errorMsg: 'common.district', isChoose: true },
    { fieldName: 'township', errorMsg: 'common.township', isChoose: true },
    { fieldName: 'address', errorMsg: 'common.address' },
]

const ShopForm = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = JSON.parse(storage.session.getItem('user'));
    const commonReducer = useSelector(state => state.common);
    const shopReducer = useSelector(state => state.shop);
    const [form] = Form.useForm();
    const [parameter, setParameter] = useState({});
    const [mode, setMode] = useState('create');
    const [openDays, setOpenDays] = useState(constant.openDays);
    const [type, setType] = useState('customHour');
    const [sameHour, setSameHour] = useState(false);
    const [shopImages, setShopImages] = useState([]);

    useEffect(() => {
        dispatch(getCountry());
        dispatch(getS3Url());

        if (props.match && props.match.params && props.match.params.shopId) {
            detailShop(props.match.params.shopId);
            setMode('update')
        }
    }, [dispatch])

    const detailShop = async (shopId) => {
        let result = await dispatch(getShopDetail({ id: shopId })).unwrap();
        let {
            province, country, district, township, address, lat, long,
            name, shortName, phone, email, openHoursType, shopImages, openHours
        } = result.data;

        setShopImages(shopImages);
        setType(openHoursType);
        if (openHours && openHours.length) setOpenDays(openHours)
        dispatch(getProvinceByCountry({ countryId: country.id }));
        dispatch(getDistrictByProvince({ provinceId: province.id }));
        dispatch(getTownshipByDistrict({ districtId: district.id }));

        const objParams = {
            lat, long, name, shortName, phone, email,
            country: country.id, province: province.id,
            district: district.id, township: township.id,
            address: address, type: openHoursType, openHours
        }
        setParameter(objParams)
        form.setFieldsValue(objParams)
    }

    const handleGetCropImage = (index, val) => {
        let images = cloneDeep(shopImages);
        images[index] = val;
        setShopImages(images);
    }

    const submitForm = async () => {
        const args = { ...parameter }
        let validate = helper.validateFields(requiredFields, args);
        if (validate) return false;

        let arrImage = []
        for (let i = 0; i < shopImages.length; i++) {
            if (shopImages[i]) {
                if (shopImages[i].includes('blob')) {
                    let result = await dispatch(uploadImage({ file: shopImages[i], subInfo: JSON.stringify({ model: 'SHOP' }), isUpload: true })).unwrap();
                    let { err, data } = result.data;

                    if (err && err === 200) {
                        arrImage[i] = data
                    } else {
                        helper.toast('error', i18next.t('common.internalServerError'))
                    }
                } else {
                    arrImage[i] = shopImages[i]
                }
            }
        }
        setShopImages(arrImage)

        let openHours = []
        openDays.forEach(item => {
            openHours.push({
                ...item,
                "timeFrom": item['openHours']['timeFrom'],
                "timeTo": item['openHours']['timeTo']
            })
        })

        const paramsToSend = {
            "address": args.address,
            "country": args.country,
            "district": args.district,
            "email": args.email,
            "lat": args.lat,
            "long": args.long,
            "name": args.name,
            "openHoursType": type,
            "phone": args.phone,
            "province": args.province,
            "shopImages": arrImage,
            "shortName": args.shortName,
            "township": args.township,
            "openHours": openHours,
            "merchantId": user.id
        }

        dispatch(setLoading(true))
        if (mode === 'create') {
            await dispatch(createShop(paramsToSend)).unwrap();
        } else {
            paramsToSend.shopId = props.match.params.shopId
            await dispatch(updateShop(paramsToSend)).unwrap();
        }
        dispatch(setLoading(false))
        helper.toast('success', i18next.t('common.successfully'));
        history.push('/shops');
    }

    const handleChangeOpenDay = (attribute, val, index) => {
        let dataCopy = JSON.parse(JSON.stringify(openDays));
        dataCopy[index][attribute] = val
        if (attribute === 'status' && !val) {
            dataCopy[index]['openHours']['timeFrom'] = '-'
            dataCopy[index]['openHours']['timeTo'] = '-'
        }

        if (['timeFrom', 'timeTo'].includes(attribute)) {
            if (sameHour) {
                dataCopy.forEach(item => {
                    if (item.status) item.openHours[attribute] = val
                })
            } else {
                dataCopy[index]['openHours'][attribute] = val
            }
        }

        setOpenDays(dataCopy)
    }

    return (
        <div>
            {shopReducer.isLoading && <Loading />}
            <h2 className="page-header">{mode === 'create' ? i18next.t('common.createShop') : i18next.t('common.updateShop')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            {mode === 'update' && shopReducer.shopDetail?.qrcode && <Col md={6} style={{ position: 'absolute', right: '100px' }}>
                                <QRView value={shopReducer.shopDetail.qrcode} size={150} subInfo={shopReducer.shopDetail} />
                            </Col>}
                            <Form
                                initialValues={{ type }}
                                form={form}
                                name="basic"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 14 }}
                                onValuesChange={(changedValues, allValues) => setParameter(allValues)}>
                                <fieldset>
                                    <legend>{i18next.t('common.basicInfo')}</legend>
                                    <Row>
                                        <Col md={16}>
                                            <Form.Item name="name" label={i18next.t('common.name')} required={true}>
                                                <Input placeholder={i18next.t('common.name')} />
                                            </Form.Item>

                                            <Form.Item name="shortName" label={i18next.t('common.shortName')} required={true}>
                                                <Input placeholder={i18next.t('common.shortName')} />
                                            </Form.Item>

                                            <Form.Item name="phone" label={<Tooltip title={i18next.t('common.phoneNumberInfo')}>
                                                <span>{i18next.t('common.phone')}</span> &nbsp;
                                                 <InfoCircleFilled />
                                            </Tooltip>}  >
                                                <Input placeholder={i18next.t('common.phone')} />
                                            </Form.Item>

                                            <Form.Item name="email" label={i18next.t('common.email')} >
                                                <Input placeholder={i18next.t('common.email')} />
                                            </Form.Item>

                                            <Form.Item name="country" label={i18next.t('common.country')} required={true}>
                                                <Select placeholder={i18next.t('common.country')} allowClear={true}
                                                    onChange={e => e ? dispatch(getProvinceByCountry({ countryId: e })) : dispatch(clearProvince())}>
                                                    {commonReducer.countries.map((item, index) => <Select.Option key={`country_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="province" label={i18next.t('common.region')} required={true}>
                                                <Select placeholder={i18next.t('common.region')} allowClear={true}
                                                    onChange={e => e ? dispatch(getDistrictByProvince({ provinceId: e })) : dispatch(clearDistrict())}>
                                                    {commonReducer.provinces.map((item, index) => <Select.Option key={`region_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="district" label={i18next.t('common.district')} required={true}>
                                                <Select placeholder={i18next.t('common.district')} allowClear={true}
                                                    onChange={e => e ? dispatch(getTownshipByDistrict({ districtId: e })) : dispatch(clearTownship())}>
                                                    {commonReducer.districts.map((item, index) => <Select.Option key={`district_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="township" label={i18next.t('common.township')} required={true}>
                                                <Select placeholder={i18next.t('common.township')} allowClear={true}>
                                                    {commonReducer.townships.map((item, index) => <Select.Option key={`township_${index}`} value={item.id}>{item.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="address" label={i18next.t('common.address')} required={true}>
                                                <Input.TextArea placeholder={i18next.t('common.address')} />
                                            </Form.Item>

                                            <Form.Item name="lat" label={i18next.t('common.lat')} >
                                                <Input type="number" placeholder={i18next.t('common.lat')} />
                                            </Form.Item>

                                            <Form.Item name="long" label={i18next.t('common.long')} >
                                                <Input type="number" placeholder={i18next.t('common.long')} />
                                            </Form.Item>

                                            <Form.Item className="shop_images" label={i18next.t('common.shopImages')}>
                                                <Row style={{ position: 'relative', left: '-23%' }}>
                                                    {
                                                        shopImages.map((item, index) => <Col sm={24} md={7} key={'shop_image_' + index}>
                                                            <Button style={{ position: 'absolute', zIndex: 1 }} htmlType="button" icon={<CloseSquareOutlined />} onClick={() => {
                                                                let dataCopy = cloneDeep(shopImages);
                                                                let val = dataCopy[index]
                                                                if (val) {
                                                                    helper.confirm(i18next.t('question.removeImage')).then(rs => {
                                                                        dataCopy = dataCopy.filter((i, idx) => idx !== index)
                                                                        setShopImages(dataCopy);
                                                                    })
                                                                } else {
                                                                    dataCopy = dataCopy.filter((i, idx) => idx !== index)
                                                                    setShopImages(dataCopy);
                                                                }
                                                            }} />
                                                            <Image s3Url={commonReducer.s3Url} imgId={'shop_image_item_' + index}
                                                                defaultImg={item}
                                                                ratio={16 / 9}
                                                                mxWidth={1280}
                                                                mxHeight={720}
                                                                getCroppedImage={val => handleGetCropImage(index, val)} />
                                                        </Col>)
                                                    }
                                                    <Col md={4}>
                                                        <Button htmlType="button" icon={<PlusSquareOutlined />} onClick={() => {
                                                            let dataCopy = cloneDeep(shopImages);
                                                            dataCopy.push('');
                                                            setShopImages(dataCopy);
                                                        }} />
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </fieldset>

                                <fieldset>
                                    <legend>{i18next.t('common.openHoursInfo')}</legend>

                                    <Row>
                                        <Col md={24}>
                                            <Form.Item name="type" label={i18next.t('common.openClostHours')} required={true}>
                                                <Radio.Group value={type} onChange={e => setType(e.target.value)}>
                                                    {['247', 'customHour'].map((item, index) => <Radio style={{ marginRight: '100px' }} key={`kyc_type_${index}`} value={item} >{i18next.t(`common.${item}`)}</Radio>)}
                                                </Radio.Group>
                                            </Form.Item>

                                            {type === 'customHour' && <>
                                                <div style={{ position: 'absolute', right: '100px' }}>
                                                    <Checkbox id="sameHour" checked={sameHour} onChange={e => setSameHour(e.target.checked)} />&nbsp;&nbsp;
                                                    <label style={{ cursor: 'pointer' }} htmlFor="sameHour" className="label">{i18next.t('common.sameHour')}</label>
                                                </div>
                                                {openDays.map((item, index) => <Row className="mb-3" key={'open_day_' + index}>
                                                    <Col md={6}>
                                                        <Switch onChange={e => handleChangeOpenDay('status', e, index)} checked={item.status} />
                                                        &nbsp;&nbsp;
                                                        <label htmlFor="">{item.day}</label>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col sm={24} md={5}><label htmlFor="">{i18next.t('common.from')}</label></Col>
                                                            <Col sm={24} md={19}><Input onChange={e => handleChangeOpenDay('timeFrom', e.target.value, index)} disabled={!item.status} value={item.openHours.timeFrom} /></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col sm={24} md={3}><label htmlFor="">{i18next.t('common.to')}</label></Col>
                                                            <Col sm={24} md={19}><Input onChange={e => handleChangeOpenDay('timeTo', e.target.value, index)} disabled={!item.status} value={item.openHours.timeTo} /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>)}

                                            </>}
                                        </Col>
                                    </Row>
                                </fieldset>

                                <Row>
                                    <Col md={24} style={{ textAlign: 'center' }}>
                                        <Button className="mr-3" htmlType="button" onClick={() => history.push('/shops')}
                                            icon={<ArrowLeftOutlined />}>
                                            {i18next.t('common.back')}
                                        </Button>

                                        <Button type="primary" htmlType="button" onClick={() => submitForm()}
                                            icon={<SaveOutlined />}>
                                            {i18next.t('common.submit')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopForm;