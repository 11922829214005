import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Tag, Form, Row, Col, DatePicker, Button } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import i18next from 'i18next';
import moment from 'moment';

import { earnPointHistory, exportEarnPointHistory } from './pointSlice';
import helper from '../../services/helper';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const columns = [
    {
        title: '#',
        key: '#',
        dataIndex: 'no'
    },
    {
        title: i18next.t('common.transRefId'),
        dataIndex: 'transRefId',
        key: 'transRefId'
    },
    {
        title: i18next.t('common.phone'),
        key: 'phone',
        dataIndex: 'phone'
    },
    {
        title: i18next.t('common.transactionId'),
        key: 'transactionId',
        dataIndex: 'transactionId'
    },
    {
        title: i18next.t('common.promotion'),
        key: 'promotionName',
        dataIndex: 'promotionName'
    },
    {
        title: i18next.t('common.point'),
        key: 'point',
        dataIndex: 'point'
    },
    {
        title: i18next.t('common.transDate'),
        key: 'transactionDate',
        dataIndex: 'transactionDate',
        render: (value) => <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
    }
]

const EarnPointHistory = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [search, setSearch] = useState({});
    const [pagination, setPagination] = useState({ position: ["bottomLeft"] });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [dispatch])

    var skip = 0, limit = 10;
    const fetchData = async (args, isExport) => {
        setLoading(true);
        if (args) {
            skip = (parseInt(args.current) - 1) * parseInt(args.pageSize)
            limit = parseInt(args.pageSize)
        }

        let objSearch = {}
        if (search.length) {
            search.forEach(item => {
                objSearch[item.name[0]] = item.value
            })

            if (objSearch.createdAt) {
                objSearch.startDate = moment(objSearch.createdAt[0]).format("YYYY-MM-DD")
                objSearch.endDate = moment(objSearch.createdAt[1]).format("YYYY-MM-DD")
                delete objSearch.createdAt
            }
        }

        if (isExport) {
            let exported = await dispatch(exportEarnPointHistory({ ...objSearch, skip: 0, limit: -1 })).unwrap();
            setLoading(false);
            window.open(exported.link, '_blank')
        }

        const params = {
            ...objSearch,
            skip: skip,
            limit: limit
        }

        const result = await dispatch(earnPointHistory(params)).unwrap();

        setData(helper.assignOrderNumber(result.data, skip));
        setPagination({ ...pagination, total: result.total || 0 });
        setLoading(false);
    }
    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.earnPoint')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Form form={form}
                                layout="vertical"
                                onFinish={value => fetchData()}
                                onFieldsChange={(changedValues, allValues) => setSearch(allValues)}>
                                <Row>
                                    <Col md={12}></Col>
                                    <Col md={6}>
                                        <Form.Item name="createdAt" label={i18next.t('common.transDate')}>
                                            <RangePicker format={dateFormat} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={6} style={{ marginTop: '30px' }}>
                                        <Button className="mr-2" icon={<SearchOutlined />} type="default" htmlType="submit">{i18next.t('common.search')}</Button>
                                        <Button icon={<DownloadOutlined />} onClick={() => fetchData('', true)} type="default" htmlType="button">{i18next.t('common.export')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <label htmlFor="" style={{ float: "right", fontSize: "larger", fontWeight: "bold" }}>Count: {pagination.total}</label>
                            <Table columns={columns} dataSource={data} className="table-wrapper" loading={isLoading}
                                pagination={pagination} onChange={changed => fetchData(changed)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EarnPointHistory;