import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';


export const findSubmerchants = createAsyncThunk(
    'findSubmerchants',
    async (data) => {
        return await api.findSubmerchants(data);
    }
)

export const clientRolesList = createAsyncThunk(
    'clientRolesList',
    async () => {
        return await api.clientRolesList()
    }
)

export const getSubmerchantDetail = createAsyncThunk(
    'getSubmerchantDetail',
    async (data) => await api.getSubmerchantDetail(data)
)

export const createSubmerchant = createAsyncThunk(
    'createSubmerchant',
    async (data) => await api.createSubmerchant(data)
)

export const updateSubmerchant = createAsyncThunk(
    'updateSubmerchant',
    async (data) => await api.updateSubmerchant(data)
)

export const activeSubMerchant = createAsyncThunk(
    'activeSubMerchant',
    async (data) => api.activeSubmerchant(data)
)

export const lockSubMerchant = createAsyncThunk(
    'lockSubMerchant',
    async (data) => api.lockSubmerchant(data)
)

export const exportSubMerchant = createAsyncThunk(
    'exportSubMerchant',
    async (data) => await api.exportSubMerchant(data)
)

const initialState = {
    data: [],
    roles: [],
    isLoading: false,
    subMerchantDetail: {}
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findSubmerchants.fulfilled, (state, action) => {
                state.data = action.payload.data;
            })
            .addCase(clientRolesList.fulfilled, (state, action) => {
                state.roles = action.payload.data;
            })
            .addCase(getSubmerchantDetail.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubmerchantDetail.fulfilled, (state, action) => {
                state.isLoading = false
                state.subMerchantDetail = action.payload.data
            })
            .addCase(createSubmerchant.fulfilled, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateSubmerchant.fulfilled, (state, action) => {
                state.isLoading = true
            })
            .addCase(createSubmerchant.pending, (state, action) => {
                state.isLoading = false
            })
            .addCase(updateSubmerchant.pending, (state, action) => {
                state.isLoading = false
            })
            .addCase(activeSubMerchant.fulfilled, (state, action) => {
                state.activeSuccess = true
            })
            .addCase(lockSubMerchant.fulfilled, (state, action) => {
                state.lockSuccess = true
            })
            .addCase(exportSubMerchant.fulfilled, (state, action) => {
                state.exportSubMerchantSuccess = true
            })
    }
})

export const { setLoading } = employeeSlice.actions;

export default employeeSlice.reducer;