import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './dashboard';
import TransHistory from './transHistory';
import ShopManagement from './shop';
import EmployeeManagement from './employee';
import Profile from './profile';
import EarnPoint from './point/earnPointHistory';
import SettlePoint from './point/settlePoint';
import ApiKey from './apiKey';
import RolePermission from './role';

import EmployeeForm from './employee/employeeForm';
import ShopForm from './shop/shopForm';

import Loading from './loading';

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route path='/dashboard' exact component={Dashboard} />
                <Route path='/transaction-history' component={TransHistory} />
                <Route path='/shops' component={ShopManagement} />
                <Route path='/submerchants' component={EmployeeManagement} />
                <Route path='/profile' component={Profile} />
                <Route path='/earn-point-history' component={EarnPoint} />
                <Route path='/settlement-point' component={SettlePoint} />
                <Route path='/api-keys' component={ApiKey} />
                <Route path='/user-roles' component={RolePermission} />

                <Route path='/submerchant/create' component={EmployeeForm} />
                <Route path='/submerchant/update/:subMerchantId' component={EmployeeForm} />

                <Route path='/shop/create' component={ShopForm} />
                <Route path='/shop/update/:shopId' component={ShopForm} />
            </Switch>
        </Suspense>
    )
}

export default Routes
