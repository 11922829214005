import React, { useState } from 'react';
import QRCode from "react-qr-code";
import { Button, Row, Col, Modal } from 'antd';
import { PrinterOutlined, DownloadOutlined, CheckCircleFilled } from '@ant-design/icons';
import i18next from 'i18next';
import { toPng } from 'html-to-image';

import logoGreen from '../../assets/images/logo-green.png';
import Loading from '../../features/loading';

const style = {
    position: "relative",
    background: "white",
    border: "3px solid #2f962d",
    marginBottom: "20px",
    width: "100%",
    boxShadow: "0 1px 1px rgb(0 0 0 / 10%)",
    borderRadius: "10px",
    padding: "25px 0px 30px 0px"
}

const QrCodeView = ({ value, size, title = i18next.t('common.qrcode'), customStyle, subInfo = {} }) => {
    const [showModal, setShowModal] = useState(false);
    const [isDownload, setDownload] = useState(false);
    const [isPrint, setPrint] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onDownload = () => {
        setIsLoading(true);
        const selector = document.querySelector("#qrcode-preview");
        setTimeout(() => {
            toPng(selector)
                .then(function (dataUrl) {
                    setIsLoading(false);
                    var a = document.createElement("a"); //Create <a>
                    a.href = dataUrl //Image Base64 Goes here
                    a.download = subInfo.name + ".png"; //File name Here
                    a.click(); //Downloaded file
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.error('oops, something went wrong!', error);
                });
        }, 1)

    }

    const onPrint = () => {
        const selector = document.querySelector("#qrcode-preview");

        let w = 1000;
        let h = 800;
        let y = window.outerHeight / 2 + window.screenY - (h / 2);
        let x = window.outerWidth / 2 + window.screenX - (w / 2);
        let popupWinindow = window.open('', '_blank', 'width=' + w + ', height=' + h + ', top=' + y + ', left=' + x + ', scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head></head><body style="width: 300px !important; text-align: center;" onload="window.print()">' + selector.innerHTML + '</html>');

        popupWinindow.document.close();

        popupWinindow.onafterprint = function () {
            console.log("Printing completed...");
            popupWinindow.close();
        }
    }

    const RenderBtnFooter = () => {
        return (
            <>
                {(showModal && (isDownload || isPrint)) && <Row style={{ marginLeft: 0 }}>
                    <Col span={17}><Button onClick={() => isDownload ? onDownload() : onPrint()} icon={<CheckCircleFilled />}>{i18next.t('common.confirm?')}</Button></Col>
                </Row>}
                {(showModal && !isDownload && !isPrint) && <Row style={{ marginLeft: 0 }}>
                    <Col span={10}><Button onClick={() => onPrint()} icon={<PrinterOutlined />}>{i18next.t('common.print')}</Button></Col>
                    <Col span={12}><Button onClick={() => onDownload()} icon={<DownloadOutlined />}>{i18next.t('common.download')}</Button></Col>
                </Row>}
            </>
        )
    }

    return (
        <>
            <Row className="mt-3 qr-code">
                <Col span={24}>
                    <Row>
                        <fieldset style={{ cursor: 'pointer' }}
                            onClick={() => setShowModal(true)}>
                            <legend className="legend-top">{title}</legend>
                            <QRCode value={value.trim()} size={size} />
                            <legend className="legend-bottom">{i18next.t('common.scanToPay')}</legend>
                        </fieldset>
                    </Row>
                    <Row style={{ marginLeft: '-35px' }}>
                        <Col span={9}><Button onClick={() => { setPrint(true); setShowModal(true); }} icon={<PrinterOutlined />}>{i18next.t('common.print')}</Button></Col>
                        <Col span={12}><Button onClick={() => { setDownload(true); setShowModal(true); }} icon={<DownloadOutlined />}>{i18next.t('common.download')}</Button></Col>
                    </Row>
                </Col>
            </Row>
            <Modal visible={showModal} title={(isDownload ? i18next.t('common.download') : (isPrint ? i18next.t('common.print') : '')) + (' ' + title)} onCancel={() => {
                setShowModal(false);
                setPrint(false);
                setDownload(false);
            }}
                className="modal-qrcode-preview"
                footer={<RenderBtnFooter />}>
                {isLoading && <Loading />}
                <div id="qrcode-preview">
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} >
                            <div style={style}>
                                <img src={logoGreen} alt="logo-green" style={{ width: '90px' }} />
                                <br />
                                <br />
                                <span> နှင့် <br /> ငွေပေးချေလိုက်ပါ </span>
                                <br />
                                <br />
                                <QRCode value={value} size={size} />
                                <br />
                                <br />
                                <span className="label">{subInfo.name}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default QrCodeView;