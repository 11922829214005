import React, { Component } from 'react';
import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import i18next from 'i18next';

import logo from '../../assets/images/logo_small.png';

export default class ImageCrop extends Component {
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 250,
      aspect: this.props.ratio || 16 / 9,
    },
    show: true,
    mocalShowHide: "custom_modal"
  };

  componentDidMount() {
    this.setState({ modalShowHide: "custom_modal hide" });
  }

  closeModal = () => {
    this.setState({ modalShowHide: "custom_modal hide" });
  }
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src: reader.result });

        this.setState({ modalShowHide: "custom_modal show" });
      }


      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
      this.props.getCroppedImage(croppedImageUrl);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = this.props.mxWidth || 1200, maxHeight = this.props.mxHeight || 1200 / (this.props.ratio || 16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/png",
        1
      );
    });
  }


  render() {
    const { crop, croppedImageUrl, src, modalShowHide } = this.state;
    let bgImage = croppedImageUrl ? croppedImageUrl : logo;
    bgImage = !croppedImageUrl && this.props.defaultImg ? this.props.s3Url + this.props.defaultImg : bgImage;
    let divStyle = {
      backgroundImage: 'url(' + bgImage + ')',
      backgroundSize: '95%',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      maxWidth: '200px',
      maxHeight: '150px',
      borderRadius: '5px'
    };
    return (
      <div>
        <input style={{ display: "none" }} type="file" id={"file1" + this.props.imgId} accept="image/*" onChange={this.onSelectFile} />
        <label htmlFor={"file1" + this.props.imgId} className="btn-3" style={divStyle}>
          <CameraOutlined />
        </label>

        <div className={src ? modalShowHide : "modal"}>
          <div className="text-right">
            {src && <Button onClick={this.closeModal}>{i18next.t('common.cropClose')}</Button>}
          </div>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
        </div>
      </div>
    );
  }
}
