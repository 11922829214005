import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';


export const findShops = createAsyncThunk(
    'findShops',
    async (data) => {
        return await api.findShops(data);
    }
)

export const getShopDetail = createAsyncThunk(
    'getShopDetail',
    async (data) => await api.getShopDetail(data)
)

export const createShop = createAsyncThunk(
    'createShop',
    async (data) => await api.createShop(data)
)

export const updateShop = createAsyncThunk(
    'updateShop',
    async (data) => await api.updateShop(data)
)

export const lockShop = createAsyncThunk(
    'lockShop',
    async (data) => api.lockShop(data)
)

export const activeShop = createAsyncThunk(
    'activeShop',
    async (data) => await api.activeShop(data)
)

export const exportShops = createAsyncThunk(
    'exportShops',
    async (data) => await api.exportShops(data)
)

const initialState = {
    data: [],
    shopDetail: {},
    isLoading: false
}

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findShops.fulfilled, (state, action) => {
                state.data = action.payload.data;
            })
            .addCase(getShopDetail.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getShopDetail.fulfilled, (state, action) => {
                state.shopDetail = action.payload.data;
                state.isLoading = false
            })
            .addCase(createShop.fulfilled, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateShop.fulfilled, (state, action) => {
                state.isLoading = true
            })
            .addCase(createShop.pending, (state, action) => {
                state.isLoading = false
            })
            .addCase(updateShop.pending, (state, action) => {
                state.isLoading = false
            })
            .addCase(activeShop.fulfilled, (state, action) => {
                state.activeSuccess = true
            })
            .addCase(lockShop.fulfilled, (state, action) => {
                state.lockSuccess = true
            })
            .addCase(exportShops.fulfilled, (state, action) => {
                state.exportSuccess = true
            })
    }
})

export const { setLoading } = shopSlice.actions;

export default shopSlice.reducer;