import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Tabs, Form } from 'antd';
import { InfoCircleOutlined, SecurityScanOutlined, ControlOutlined } from '@ant-design/icons';
import i18next from 'i18next';

import { getS3Url } from '../../app/commonSlice';
import storage from '../../services/storage';
import InfoDetails from './inforDetails';
import Security from './security';
import Devices from './devices';

const { TabPane } = Tabs;

const Profile = () => {
    const user = JSON.parse(storage.session.getItem('user'));
    const dispatch = useDispatch();
    const commonReducer = useSelector(state => state.common);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getS3Url())
    }, [dispatch])

    return (
        <div>
            <h2 className="page-header">{i18next.t('menu.profile')}</h2>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={<>
                                        <InfoCircleOutlined />
                                        <span>{i18next.t('common.infoDetails')}</span>
                                    </>} key="1">
                                    <Row className="mt-5">
                                        <Col span={24}>
                                            <Row>
                                                <InfoDetails user={user} s3Url={commonReducer.s3Url} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane
                                    tab={<>
                                        <SecurityScanOutlined />
                                        <span>{i18next.t('common.loginSecurity')}</span>
                                    </>} key="2">
                                    <Row className="mt-5">
                                        <Col span={24}>
                                            <Row>
                                                <Security />
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane
                                    tab={<>
                                        <ControlOutlined />
                                        <span>{i18next.t('common.devices')}</span>
                                    </>} key="3">
                                    <Row className="mt-5">
                                        <Row>
                                            <Devices user={user} />
                                        </Row>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Profile;